
import { motion } from "framer-motion";
import { useState } from "react";
import HomeSlider from "../components/HomeSlider";
import { HomeSliderData } from "../data/slider/ev-giyim-slider-data";
import { useLenis } from '@studio-freight/react-lenis';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import HomeProductsMenu from "../components/HomeProductsMenu";
import HomeMenu from "../components/HomeMenu";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 200px, rgba(0,0,0,1) 200px, rgba(0,0,0,1) 200px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 200px)`;


const EvGiyim = () => {
    const { t, i18n } = useTranslation();
    const lenis = useLenis();
    const list = {
        visible: {
            opacity: 1,

            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
        hidden: { opacity: 0 },

    }

    const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }


    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);

    const handleDownArrowClick = () => {
        if (lenis) {
            const viewportHeight = window.innerHeight;
            lenis.scrollTo(viewportHeight - 80);
        }
    };

    return (
        <section className="overflow-x-hidden">
            <section>
                <div className="relative">
                    <motion.div
                        initial={false}
                        animate={
                            isLoaded && isInView
                                ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                                : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                        }
                        transition={{ duration: isInView ? 0.7 : 0 }}

                        onViewportEnter={() => setIsInView(true)}

                    ><img src={'/images/ev-giyim/banner.jpg'} onLoad={() => setIsLoaded(true)} className="object-cover w-full h-screen" /></motion.div>
                    <div className="h-full absolute w-full top-0 left-0  grid grid-cols-9">
                        <div className="col-start-2 col-end-9   flex flex-col">
                            <div className="h-1/3 w-full "></div>
                            <motion.div

                                initial={false}
                                animate={isLoaded && isInView && isInView ? { translateX: 0, opacity: 1 } : { translateX: 100, opacity: 0 }}
                                transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 1 : 0 }}

                                className="h-1/3 w-full text-3xl md:text-5xl lg:text-8xl font-thin text-white  flex justify-end items-end">
                                {t('home-banner-text-1/2')}
                                <br />
                                {t('home-banner-text-2/2')}

                            </motion.div>
                            <div className="h-1/3 w-full flex justify-start items-end">

                                <div className="flex  pb-10 h-full items-end  group">

                                    <div className="flex  space-x-4 items-center ">
                                        <motion.img
                                            initial={false}
                                            animate={isLoaded && isInView && isInView ? { translateY: 0, opacity: 1 } : { translateY: 100, opacity: 0 }}
                                            transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 0.7 : 0 }}
                                            className="h-5 w-5" src="/images/icons/follow-us.svg"
                                        />
                                        <motion.div
                                            initial={false}
                                            animate={isLoaded && isInView && isInView ? { translateY: 0, opacity: 1 } : { translateY: 100, opacity: 0 }}
                                            transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 1 : 0 }}

                                            className="text-white tracking-widest relative"><span className="block  ">
                                                <div className="h-full w-full  absolute top-0 left-0   grid grid-cols-5 opacity-0 group-hover:opacity-100 z-10  transition-all duration-500 gap-2" >

                                                    <div className="h-6   flex justify-center items-center translate-y-6 group-hover:translate-y-0 transition-all duration-500 delay-100  cursor-pointer">

                                                        <div className="h-6 w-6  flex justify-center items-center hover:scale-105 transition-all duration-300">
                                                            <img src="/images/home-contact-icons/5.svg" />
                                                        </div>

                                                    </div>



                                                    <div className="h-6   flex justify-center items-center translate-y-6 group-hover:translate-y-0 transition-all duration-500 delay-200  cursor-pointer">
                                                        <div className="h-6 w-6  flex justify-center items-center hover:scale-105 transition-all duration-300">
                                                            <img src="/images/home-contact-icons/1.svg" className="h-full" />
                                                        </div>
                                                    </div>

                                                    <div className="h-6   flex justify-center items-center translate-y-6 group-hover:translate-y-0 transition-all duration-500 delay-300  cursor-pointer">

                                                        <div className="h-6 w-6  flex justify-center items-center hover:scale-105 transition-all duration-300">
                                                            <img src="/images/home-contact-icons/3.svg" className="h-full" />
                                                        </div>

                                                    </div>
                                                    <div className="h-6   flex justify-center items-center translate-y-6 group-hover:translate-y-0 transition-all duration-500 delay-[400ms]  cursor-pointer">

                                                        <div className="h-6 w-6  flex justify-center items-center hover:scale-105 transition-all duration-300">
                                                            <img src="/images/home-contact-icons/4.svg" className="h-full" />
                                                        </div>

                                                    </div>
                                                    <div className="h-6   flex justify-center items-center translate-y-6 group-hover:translate-y-0 transition-all duration-500 delay-500  cursor-pointer">

                                                        <div className="h-6 w-6  flex justify-center items-center hover:scale-105 transition-all duration-300">
                                                            <img src="/images/home-contact-icons/2.svg" className="h-full" />
                                                        </div>

                                                    </div>

                                                </div>
                                                <span className="block group-hover:opacity-0 transition-all duration-500 group-hover:-translate-y-6 font-thin "> {t('home-banner-follow-us')}</span></span></motion.div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section className="h-full w-full top-0 left-0  grid grid-cols-9">
                <div className="col-start-2 col-end-9  flex justify-between">
                    <motion.h2
                        initial={false}
                        animate={isLoaded && isInView && isInView ? { translateX: 0, opacity: 1 } : { translateX: 100, opacity: 0 }}
                        transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 1.4 : 0 }}

                        className="text-black responsive-50-35 leading-[3rem] py-20  font-extralight">
                        {t('home-inner-banner-text-1/3')}
                        <br />
                        {t('home-inner-banner-text-2/3')}
                        <br />
                        {t('home-inner-banner-text-3/3')}</motion.h2>
                    <div className="pt-10  relative overflow-hidden  hover:scale-105 transition-all duration-500 cursor-pointer" onClick={handleDownArrowClick}>
                        <motion.div
                            initial={false}
                            animate={isLoaded && isInView && isInView ? { translateX: 100, translateY: -100, opacity: 1 } : { translateX: 0, translateY: 0, opacity: 1 }}
                            transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 1.4 : 0 }}
                            className="absolute top-10 left-0 h-20 w-20 z-40 bg-white">

                        </motion.div>
                        <motion.img

                            initial={false}
                            animate={isLoaded && isInView && isInView ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 1 }}
                            transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 1.4 : 0 }}

                            className="h-20 w-20  " src="/images/icons/push-down.svg" />


                    </div>

                </div>


            </section>


            {/* <HomeSlider HomeSliderData={HomeSliderData} /> */}

            <HomeProductsMenu HomeSliderData={HomeSliderData} />


            <motion.section initial="hidden" whileInView="visible" variants={list} className="grid grid-cols-9 bg-[#D2CEC1]">
                <div className="col-start-2 col-end-9  pt-40">
                    <div className="text-center text-black  font-medium">
                        <motion.h5 variants={itemText} className="responsive-65-50   mb-5 font-extralight !opacity-50"> {t('home-leaf-banner-header-text-1/2')}
                            <br />
                            {t('home-leaf-banner-header-text-2/2')}
                        </motion.h5>
                        <motion.p variants={itemText} className="text-center text-base text-[#5F5F5F] mb-10  font-extralight"> {t('home-leaf-banner-content-text-1/3')}
                            <br />
                            {t('home-leaf-banner-content-text-2/3')}
                            <br />
                            {t('home-leaf-banner-content-text-3/3')}


                        </motion.p>
                    </div>
                    <div className="flex justify-center">

                        <div className="inline-block relative mb-10 hover:scale-105 cursor-pointer transition-transform duration-500">
                            <motion.div variants={{ visible: { translateX: 0, opacity: 1, transition: { duration: 1 } }, hidden: { translateX: 30, opacity: 0 } }} className="h-px w-full bg-black relative left-4 "></motion.div>
                            <Link to={'/kurumsal'} ><motion.h5 variants={{ visible: { translateX: 0, opacity: 1, transition: { duration: 1 } }, hidden: { translateX: -30, opacity: 0 } }} className="font-extralight !opacity-50">   {t('read-more')}</motion.h5></Link>

                        </div>
                    </div>

                    <motion.div variants={itemText} className="flex justify-center">

                        <img src={'/images/ev-giyim-home/leaf.png'} />
                    </motion.div>

                </div>

            </motion.section>

            <section className="grid grid-cols-9 bg-[#695D54] py-20">
                <motion.div initial="hidden" whileInView="visible" variants={list} className="col-start-2 col-end-9  flex justify-center ">
                    <motion.div variants={{ visible: { translateY: 0, opacity: 1, transition: { duration: 1 } }, hidden: { translateY: -30, opacity: 0 } }} className=" flex flex space-x-2">

                        <p className="text-center text-white  font-thin text-xl">

                            {t('home-leaf-banner-inner-content-text-1/2')}
                            <br />
                            {t('home-leaf-banner-inner-content-text-2/2')}
                        </p>


                    </motion.div>
                </motion.div>
            </section>



            <section className="grid grid-cols-9 bg-[#F8F6ED] py-20 lg:py-56">
                <div className="col-start-2 col-end-9   flex flex-col space-y-10">
                    <motion.div initial="hidden" whileInView="visible" variants={list} className=" grid grid-cols-1 lg:grid-cols-2 ">
                        <motion.h5 variants={{ visible: { translateX: 0, opacity: 1, transition: { duration: 1 } }, hidden: { translateX: -30, opacity: 0 } }} className="font-light  text-2xl leading-loose  lg:text-5xl lg:leading-[4rem]  text-[#292929] mb-10 lg:mb-0">{t('home-last-1')}<br />{t('home-last-2')}<br />


                            <span className="font-extralight ">{t('home-last-3')}</span></motion.h5>



                        <motion.div variants={{ visible: { translateX: 0, opacity: 1, transition: { duration: 1 } }, hidden: { translateX: 30, opacity: 0 } }} className="text-base font-extralight text-[#55422F] lg:pl-10 leading-[2rem]">
                            <p className="mb-5">{t('home-last-4')}</p>
                            <p className="mb-5">{t('home-last-5')}
                            </p>
                            <p >
                                {t('home-last-6')}
                            </p>
                        </motion.div>
                    </motion.div>

                </div>

            </section>

            <HomeMenu/>

            {/* <ScrollVelocityAnimation/> */}



        </section>
    )
}

export default EvGiyim