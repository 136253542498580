import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom'

const DATA = [
    {
        title: {
            tr: 'BİZDEN HABERLER',
            en: 'NEWS FROM US'
        },
        link: 'bizden-haberler'
    },
    {
        title: {
            tr: 'FUARLAR',
            en: 'FAİRS'
        },
        link: 'fuarlar'
    },
    {
        title: {
            tr: 'KATALOGLAR',
            en: 'CATALOGS'
        },
        link: 'kataloglar'
    },
    {
        title: {
            tr: 'VİDEOLAR',
            en: 'VİDEOS'
        },
        link: 'videolar'
    },
];

const BasinOdasi = () => {
    const location = useLocation()
    const scrollRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language; 

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.pathname]);
  return (
    <div>
    <div className="grid grid-cols-9 mb-16">
        <div className="col-start-2 col-end-9   ">
            <div className="h-60 flex items-end  justify-between">
                <h5 className="text-6xl lg:text-125 font-medium lg:leading-[5.75rem]">{t('basin')} <span className="font-light">{t('odasi')}</span></h5>
                <div className="flex flex-col items-end">
                    <span className="text-base lg:text-20 font-light">{t('devami-icin-kaydir')}</span>
                    <img src="/images/logo/rightdown.svg" className="w-7" />
                </div>

            </div>
        </div>
    </div>
    <img  src={'/images/basin/banner.jpg'} />
    {/* <div className="grid grid-cols-9 mt-20 ">
        <div className="col-start-2 col-end-9   ">
            <h5 className="text-xl lg:text-55 lg:leading-10 my-1 mb-5 ">LOREM İPSUM DOLOR SİT AMET,
                <br />
                CONSECTETUER ADIPISCING ELİT, SED DİAM
            </h5>
            <p className="text-16">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
            </p>
        </div>
    </div> */}
    <div className='h-24 w-full  relative' >
        <div className='absolute -top-24 left-0'  ref={scrollRef} id="scrollTarget"></div>
    </div>

    <div className="grid grid-cols-9   my-28 "  >
        <div className="col-start-2 col-end-9     grid grid-cols-3 ">
            <div className='col-span-1 mb-20'>
            <div className="flex flex-col">
            {
                DATA.map((item, index) => {
                    return (
                        <Link key={index} to={item.link}><h5 className={` text-32  barlowCondensed `}>{item.title[currentLanguage]}</h5></Link>
                    )
                })
            }
        </div>
            </div>
            <Outlet/>

            {/* <BasinNavigation DATA={DATA} />
          
            <Outlet/> */}

        </div>
    </div>

    
</div>
  )
}

export default BasinOdasi