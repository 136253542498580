import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const variants = {
    visible: index => ({
        opacity: 1,
        scale: 1,
        transition: {
            delay: index * 0.05,
        },
    }),
    hidden: { opacity: 0, scale: 0.8 },
}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 } }
const HomeProductsMenu = ({ HomeSliderData }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; // Get the current language

    return (
        <motion.div className='grid grid-cols-9 relative '>
            {/* <div className='absolute w-full -bottom-5 h-1/3  bg-[#695D54]'></div> */}

            <div className='col-start-1 col-end-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 '>

            {HomeSliderData && HomeSliderData.map((item, index) => (
                        <div key={index} className=' relative  overflow-hidden'>
                            <Link to={item.link} className='cursor-pointer transition-all duration-500 hover:scale-105 relative block'>
                                <div className='absolute top-0 left-0 h-full w-full flex flex-col justify-start items-start z-30 p-[7%] text-white'>
                                    <h5 className='text-4xl md:text-3xl lg:text-5xl xl:text-6xl font-thin text-[#835D41] '>
                                        {item.title[currentLanguage]}
                                    </h5>
                                    <h5 className='text-base  lg:text-24 font-thin text-[#835D41]'>
                                        {item.category[currentLanguage]}
                                    </h5>
                                </div>
                                <motion.img
                                    custom={index}
                                    whileInView="visible"
                                    initial="hidden"
                                    variants={variants}
                                    src={item.image}
                                    className='h-full w-full object-cover'
                                    alt={item.title[currentLanguage]}
                                />
                            </Link>
                        </div>
                    ))}


            </div>

        </motion.div>
    )
}

export default HomeProductsMenu