import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import ModalVideo from 'react-modal-video';

const VideoComponentDATA = [
    {
        youtubeId : 'QFoxSC1yYyY',
    },
    {
        youtubeId : 'QFoxSC1yYyY',
    },
    {
        youtubeId : 'QFoxSC1yYyY',
    },
    
  ]
  

const Videolar = () => {
    const [isOpen, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
  return (
<>
<div className='col-span-2 '></div>
<div className='col-span-7  '>
    <div className=''>
     <h5 className='text-35 mb-5'>  {t('videolar')}</h5>
    </div>
    <div className='grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
{
            VideoComponentDATA.map((item,index)=>{
                return(
                    <React.Fragment>
                    <ModalVideo
                        channel="youtube"
                        youtube={{ mute: 0, autoplay: 0 }}
                        isOpen={isOpen}
                        videoId={item.youtubeId}
                        onClose={() => setOpen(false)}
                    />
                    <div onClick={() => setOpen(true)} className=" w-full bg-red-700">
                        <img className="object-cover h-full w-full" src={`https://img.youtube.com/vi/${item.youtubeId}/hqdefault.jpg`} />
                    </div>
                </React.Fragment>
                )
            })
        }
        </div>
</div>
</>
  )
}

export default Videolar