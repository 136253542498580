
import { motion } from "framer-motion";

import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 33.3%, rgba(0,0,0,1) 33.3%, rgba(0,0,0,1) 33.3%)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 33.3%)`;




const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.2,
      },
  },
  hidden: { opacity: 0 },

}

const itemAnim1 = { visible: { translateX: 0,opacity: 1, transition: { duration: 1 } }, hidden: {translateX: -30, opacity: 0 }, }
const itemAnim2 = { visible: { translateX: 0,opacity: 1, transition: { duration: 1 } }, hidden: {translateX: 30, opacity: 0 }, }



const Kurumsal = () => {
  const { t, i18n } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  return (
    <div className='overflow-x-hidden'>
      <motion.div
      initial={false}
      animate={
          isLoaded && isInView
              ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
              : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
      }
      transition={{ duration: isInView ? 0.7 : 0 }}

      onViewportEnter={() => setIsInView(true)}
      onViewportLeave={() => setIsInView(false)}>
      <img  src={'/images/kurumsal/banner.jpg'} onLoad={() => setIsLoaded(true)} className='w-full mb-20 h-96  lg:h-auto object-cover' />
      </motion.div>
      {/* <div className='grid grid-cols-14'>
        <div className='col-start-2 col-end-15 grid grid-cols-13 '>
          <div className=' col-start-1 col-end-5 '>
            <h5 className='text-65 mb-8'>HAKKIMIZDA</h5>
            <p className='text-16 mb-4'>Turkuaz Tekstil Nihat ZEYBEKCİ tarafından 1994 yılında kurularak, havlu ve bornoz imalatına başladı. İmalatta ise bukle dokuma ve dokunan ürünlerin konfeksiyon tarafından işlenme süreçleri yer aldı. Artan talebi karşılamak ve tüm üretim süreçlerini kendi kontrolü altına almak amacıyla Turkuaz Tekstil, 2005 yılında en son teknoloji ve ekipman ile donatılmış kendi nakış işletmesi Turkar Tekstil Sanayi ve Ticaret A.Ş.’yi kurdu.
            </p>
            <p className='text-16 mb-4'>13.500 m2 kapalı alana sahip olan ana tesisimizde Turkuaz Tekstil Grubu toplam 290 kişiyi istihdam etmektedir.
            </p>
            <p className='text-16 mb-4'>Turkuaz Tekstil; İtalya, İspanya, Amerika, İngiltere, Japonya, Yunanistan, Fransa, Almanya, Finlandiya, İsviçre, Avusturya, Belçika, Hollanda, İrlanda, Slovakya, Hırvatistan, Romanya, Polonya, Bulgaristan gibi ülkelere üretiminin tamamını ihraç etmektedir.

            </p>
            <p className='text-16 mb-4'>Vizyonumuz; profesyonel yönetim anlayışı ile müşterilerimize her daim kaliteyi en iyi benimsenmiş hizmet modeli ile sunmak, onların sürekli memnuniyetini sağlamak ve kendimizi durmaksızın yeni teknolojik ve sosyal gelişmelere uygun bir biçimde geliştirmek ile beraber ülkemize değer yaratan lider bir şirket olmaktır.

            </p>
          </div>
          <div className=' bg-red-400 col-start-5 col-end-13 '>
            <img width={960} height={610} src={'/images/kurumsal/kurumsal2.jpg'} />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-14'>
        <div className='col-start-1 col-end-14 grid grid-cols-2 '>
          <div className=' bg-orange-300 w-full'>
            <img width={960} height={610} src={'/images/kurumsal/uretim1.jpg'} />
          </div>
          <div className='  w-full flex flex-col justify-center '>
            <h5 className='text-65 mb-8'>ÜRETİM</h5>
            <p className='text-16 mb-4'>Turkuaz Tekstil tam entegre bir havlu ve bornoz imalatçısıdır. Üretim hatlarımız ve makinelerimiz en son teknolojik gelişmelere uygun olarak yenilenmektedir.
            </p>
            <p className='text-16 mb-4'>8 elektronik armürlü dokuma tezgâhı ve 10 elektronik jakarlı dokuma tezgâhından oluşan dokuma departmanı ile kapasitesi %50 havlu ve % 50 bornoz olmak üzere aylık 80 tona ulaşmıştır.
            </p>
            <p className='text-16 mb-4'>4 bantlı bornoz konfeksiyon ve 2 bantlı havlu konfeksiyon departmanı ile, ayda 80 ton havlu ve 50,000 adet bornoz üretebilmektedir.


            </p>
            <p className='text-16 mb-4'>Nakış bölümü 9 renkli nakış ve aplik üretimi yapabilmektedir. 3 adet 15 kafalı ve 2 adet 18 kafalı nakış makinelerinin yanında 1 adet 4 kafalı numune nakış makinesi ile donatılmıştır.

            </p>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-14'>
        <div className='col-start-2 col-end-15 grid grid-cols-2 '>
          <div className='  w-full flex flex-col justify-center'>
            <h5 className='text-65 mb-8'>KALİTE POLİTİKASI</h5>
            <p className='text-16 mb-4'>Kuruluşumuzdan bugüne en temel ve önemli ilkemiz “MÜŞTERİ MEMNUNİYETİ” olmuştur. Müşterilerimizin memnuniyetini sağlamak için en önemli kıstasın kalite olduğunun farkındayız. Bu nedenle müşterilerimizden gelen her talep büyük bir özenle yerine getirilir. Müşteri taleplerine en uygun alternatif ve çözümleri en iyi fiyata sunmak, taleplere hemen cevap vermek, zamanında doğru ve hatasız ulaştırmak, yetenekli tasarım ekibimizin desteği ile özel tasarımlar ile müşterilerimizi desteklemek bizim “Kalite” anlayışımızı özetlemektedir.
            </p>
            <p className='text-16 mb-4'>Ekip çalışması ve bölümler arasındaki uyumumuz, her zaman başarıya ulaştıran en önemli etken olmuştur.
            </p>

          </div>
          <div className=' bg-red-400'>
            <img width={960} height={610} src={'/images/kurumsal/kalite2.jpg'} />
          </div>
        </div>
      </div>




      <div className='grid grid-cols-14'>
        <div className='col-start-1 col-end-14 grid grid-cols-2 '>
          <div className=' bg-orange-300 w-full'>
            <img width={960} height={610} src={'/images/kurumsal/kurumsal2.jpg'} />
          </div>
          <div className='  w-full flex flex-col justify-center '>
            <h5 className='text-65 mb-8'>SORUMLULUKLARIMIZ</h5>
            <p className='text-16 mb-4'>Her zaman topluma, çevreye, ulusal ve uluslararası yasalara karşı sorumluluk bilincinde ilerlemeyi, insana ve insan haklarına saygı duymayı bir ilke olarak benimsemiş bir şirketiz.
            </p>
            <p className='text-16 mb-4'>Bu bakış açısı altında sosyal uyumluluk sistemimiz BSCI denetim kuruluşu (Business Social Compliance Initiative) tarafından yapılan denetimlerden başarıyla geçmiştir.
            </p>
            <p className='text-16 mb-4'>Üretim aşamalarımızın her birinde çevreye duyarlı olarak ürünlerimizde herhangi bir zararlı kimyasal kullanmadan, sadece OEKO-TEX 100 belgesine sahip hammadde, aksesuar ve ambalaj malzemeleri kullanmaktayız.



            </p>
            <p className='text-16 mb-4'>Modern tasarımlarla bütünleşmiş, insan sağlığına zararı olmayan, %100 organik pamuktan üretilmiş ürünlerimizi de müşterilerimize sunabilmek adına “ECOCERT” firmasından, “GOTS” sertifikasını almış bulunmaktayız.

            </p>
          </div>
        </div>
      </div> */}

      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-1 lg:grid-cols-2'>
        <div className=' w-full flex flex-col justify-center lg:pl-[22.2%] lg:pr-[10%] lg:py-[10%] p-[10%]'>
          <motion.h5  variants={itemAnim1} className='responsive-65  mb-8 font-light'>  {t('institutional-about-title')}</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6'>
            <p className='text-16 mb-4'> {t('institutional-about-content1')}
            </p>
            <p className='text-16 mb-4'> {t('institutional-about-content2')}
            </p>
            <p className='text-16 mb-4'> {t('institutional-about-content3')}

            </p>
            <p className='text-16 mb-4'><span className="font-bold"> {t('institutional-about-content4')}</span>
            {t('institutional-about-content5')}

            </p>
          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400  '>
          <img  className='h-full w-full object-cover' src={'/images/kurumsal/hakkimizda.jpg'} />
        </motion.div>
      </motion.div>






      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid  grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover' src={'/images/kurumsal/uretim1.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[22.2%] lg:pl-[10%] lg:py-[10%] p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='responsive-65 mb-8 font-light'>{t('production-about-title')}</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6'>
            <p className='text-16 mb-4'>{t('production-about-content1')}
            </p>
            <p className='text-16 mb-4'>{t('production-about-content2')}
            </p>
            <p className='text-16 mb-4'>{t('production-about-content3')}


            </p>
            <p className='text-16 mb-4'>{t('production-about-content4')}

            </p>
          </motion.div>
        </div>
      </motion.div>







      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <div className='  w-full flex flex-col justify-center lg:pl-[22.2%] lg:pr-[10%] lg:py-[10%]  p-[10%]'>
          <motion.h5 variants={itemAnim1} className='responsive-65 mb-8  font-light'>{t('quality-policy-about-title')}</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6'>
            <p className='text-16 mb-4'>{t('quality-policy-about-content1')}
            </p>

          </motion.div>
        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400'>
          <img  className='h-full w-full object-cover' src={'/images/kurumsal/sorumluluklarimiz.jpg'} />
        </motion.div>
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <motion.div variants={itemAnim1} className=' bg-orange-300 w-full order-2 lg:order-1'>
          <img  className='h-full w-full object-cover' src={'/images/kurumsal/kalite_politikasi.jpg'} />
        </motion.div>
        <div className='  w-full flex flex-col justify-center lg:pr-[22.2%] lg:pl-[10%] lg:py-[10%]  p-[10%] order-1 lg:order-2'>
          <motion.h5 variants={itemAnim2} className='responsive-65 mb-8 font-light'>{t('responsibilities-about-title')}</motion.h5>
          <motion.div variants={itemAnim2} className='text-customGray leading-6'>
            <p className='text-16 mb-4'>{t('responsibilities-about-content1')}
            </p>
            <p className='text-16 mb-4'>{t('responsibilities-about-content2')}
            </p>
            <p className='text-16 mb-4'>{t('responsibilities-about-content3')}



            </p>
            <p className='text-16 mb-4'>{t('responsibilities-about-content4')}

            </p>
          </motion.div>
        </div>
      </motion.div>
      <motion.div initial="hidden" whileInView="visible" variants={list} className='grid   grid-cols-1 lg:grid-cols-2'>
        <div className='  w-full flex flex-col justify-center lg:pl-[22.2%] lg:pr-[10%] lg:py-[10%]  p-[10%]'>
          <motion.h5 variants={itemAnim1} className='responsive-65  mb-8 font-light'>{t('environmental-policy-about-title')}</motion.h5>
          <motion.div variants={itemAnim1} className='text-customGray leading-6'>
            <p className='text-16 mb-4'>{t('environmental-policy-about-content1')}

            </p>
          </motion.div>


        </div>
        <motion.div variants={itemAnim2} className=' bg-red-400'>
          <img  className='h-full w-full object-cover' src={'/images/kurumsal/cevre-politikasi.jpg'} />
        </motion.div>


      </motion.div>
    </div>
  )
}

export default Kurumsal