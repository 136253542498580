import logo from './logo.svg';
import './App.css';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import 'photoswipe/dist/photoswipe.css'
import "yet-another-react-lightbox/styles.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { useEffect } from 'react';
import PageContainer from './pages/PageContainer';
import EvGiyim from './pages/EvGiyim';
import Kurumsal from './pages/Kurumsal';
import Markalar from './pages/Markalar';
import Sertifikalar from './pages/Sertifikalar';
import BasinOdasi from './pages/basin-odasi/BasinOdasi';
import Videolar from './pages/basin-odasi/videolar/Videolar';
import BizdenHaberler from './pages/basin-odasi/bizden-haberler/BizdenHaberler';
import BizdenHaberlerInner from './pages/basin-odasi/bizden-haberler/BizdenHaberlerInner';
import Fuarlar from './pages/basin-odasi/fuarlar/Fuarlar';
import FuarlarInner from './pages/basin-odasi/fuarlar/FuarlarInner';
import Kataloglar from './pages/basin-odasi/kataloglar/Kataloglar';
import BasınKiti from './pages/basin-odasi/basin-kiti/BasınKiti';
import Iletisim from './pages/Iletisim';
import Anasayfa from './pages/Anasayfa';
import EvTekstil from './pages/EvTekstil';
import Urunler from './pages/Urunler';
import EvGiyimUrunlerContainer from './components/EvGiyimUrunlerContainer';
import EvTekstilUrunlerContainer from './components/EvTekstilUrunlerContainer';
import Anasayfa2 from './pages/Anasayfa2';
import i18n from 'i18next';


function App() {
  const location = useLocation();
  


  return (
    <ReactLenis root>
    <div className="App  mx-auto lg:overflow-x-hidden ">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageContainer />}>
            <Route path="/" element={<Anasayfa2 />} />
            <Route path="/ev-giyim" element={<EvGiyim />} />
            <Route path="/ev-tekstil" element={<EvTekstil />} />
            <Route path="/kurumsal" element={<Kurumsal />} />
            <Route path="/markalar" element={<Markalar />} />
            <Route path="/sertifikalar" element={<Sertifikalar />} />

            <Route path="/ev-giyim/urunler" element={<EvGiyimUrunlerContainer />} >
            <Route path=":id">
            <Route path=":id"/>
            </Route>
            </Route>


            <Route path="/ev-tekstil/urunler" element={<EvTekstilUrunlerContainer />} >
            <Route path=":id">
            <Route path=":id"/>
            </Route>
            </Route>



            <Route path="/basin-odasi" element={<BasinOdasi />}>

            



              <Route path="videolar" element={<Videolar />} />
              <Route path="bizden-haberler" element={<BizdenHaberler />}>
                <Route path=':id' element={<BizdenHaberlerInner />} />
              </Route>
              <Route path="fuarlar" element={<Fuarlar />}>
                <Route path=':id' element={<FuarlarInner />} />
              </Route>
              <Route path="kataloglar" element={<Kataloglar />} />
              
              {/* <Route  path="basin-kiti" element={<BasınKiti/>}/> */}


            </Route>
            <Route path="iletisim" element={<Iletisim />} />
            {/* <Route  path="hakkimizda" element={<Hakkimizda/>}/>
              <Route  path="uretim" element={<Uretim/>}>
                  <Route path=":id"/>
              </Route>

              <Route  path="hizmetlerimiz" element={<Hizmetlerimiz/>}>
                  <Route path=":id"/>
              </Route>

              <Route  path="iletisim" element={<Iletisim/>}/> */}
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
    </ReactLenis>
  );
}

export default App;
