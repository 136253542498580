import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const BizdenHaberlerComponent = ({ BizdenHaberlerComponentDATA }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div className="flex flex-col space-y-4">
            {
                BizdenHaberlerComponentDATA.map((item, index) => {
                    return (
                        <Link key={index} to={item.link}>
                            <div className="flex space-x-2">
                                <img src="/images/icons/info.svg" className="h-5 w-5" alt="info icon" />
                                <h5 className={`text-16`}>
                                    {item.name[currentLanguage]} 
                                </h5>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default BizdenHaberlerComponent;
