export const DATA = [
    {
        product: 'uyku-tulumu',
        category: 'aksesuar',
        banner: '/images/ev-giyim/product/aksesuar/uyku-tulum/banner.jpg',
        title: {
            tr: 'Uyku Tulumu',
            en: 'Sleeping Bag'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/0.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/2.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/3.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/4.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/5.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/6.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/7.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/uyku-tulum/8.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/9.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/10.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/11.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/12.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/13.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/14.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/15.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/16.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/17.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/18.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/uyku-tulum/19.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/aksesuar/uyku-tulum/20.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/uyku-tulum/21.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/uyku-tulum/22.jpeg',
                img4: '/images/ev-giyim/product/aksesuar/uyku-tulum/23.jpeg',
            }
        ]
    },
    {
        product: 'yatak-grubu',
        category: 'aksesuar',
        banner: '/images/ev-giyim/product/aksesuar/yatak-grubu/banner.jpg',
        title: {
            tr: 'Yatak Grubu',
            en: 'Bed Set'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/0.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/yatak-grubu/1.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/yatak-grubu/2.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/3.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/yatak-grubu/4.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/5.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/yatak-grubu/6.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/yatak-grubu/7.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/8.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/yatak-grubu/12.jpeg',
                img3: '/images/ev-giyim/product/aksesuar/yatak-grubu/11.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/9.jpeg',
                img2: '/images/ev-giyim/product/aksesuar/yatak-grubu/10.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-giyim/product/aksesuar/yatak-grubu/13.jpeg',
            }
        ]
    },
    {
        product: 'aksesuar',
        category: 'cocuk',
        banner: '/images/ev-giyim/product/cocuk/aksesuar/banner.jpg',
        title: {
            tr: 'Aksesuar',
            en: 'Accessory'
        },
        data: [
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/cocuk/aksesuar/0.jpeg',
                img2: '/images/ev-giyim/product/cocuk/aksesuar/1.jpeg',
                img3: '/images/ev-giyim/product/cocuk/aksesuar/2.jpeg',
                img4: '/images/ev-giyim/product/cocuk/aksesuar/3.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/aksesuar/4.jpeg',
                img2: '/images/ev-giyim/product/cocuk/aksesuar/7.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/aksesuar/5.jpeg',
                img2: '/images/ev-giyim/product/cocuk/aksesuar/6.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/cocuk/aksesuar/8.jpeg',
                img2: '/images/ev-giyim/product/cocuk/aksesuar/9.jpeg',
                img3: '/images/ev-giyim/product/cocuk/aksesuar/10.jpeg',
                img4: '/images/ev-giyim/product/cocuk/aksesuar/11.jpeg',
            }
        ]
    },
    {
        product: 'cocuk',
        category: 'cocuk',
        banner: '/images/ev-giyim/product/cocuk/cocuk/banner.jpg',
        title: {
            tr: 'Çocuk',
            en: 'Child'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/cocuk/0.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/1.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/cocuk/cocuk/2.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/3.jpeg',
                img3: '/images/ev-giyim/product/cocuk/cocuk/4.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/cocuk/5.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/6.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/cocuk/7.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/8.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/cocuk/cocuk/9.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/10.jpeg',
                img3: '/images/ev-giyim/product/cocuk/cocuk/11.jpeg',
                img4: '/images/ev-giyim/product/cocuk/cocuk/12.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/cocuk/13.jpeg',
                img2: '/images/ev-giyim/product/cocuk/cocuk/14.jpeg',
            }
        ]
    },
    {
        product: 'oyun-grubu',
        category: 'cocuk',
        banner: '/images/ev-giyim/product/cocuk/oyun-grubu/banner.jpg',
        title: {
            tr: 'Oyun Grubu',
            en: 'Play Group'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/oyun-grubu/0.jpeg',
                img2: '/images/ev-giyim/product/cocuk/oyun-grubu/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/cocuk/oyun-grubu/2.jpeg',
                img2: '/images/ev-giyim/product/cocuk/oyun-grubu/3.jpeg',
            }
        ]
    },
    {
        product: '',
        category: 'yeni-dogan',
        banner: '/images/ev-giyim/product/yeni-dogan/banner.jpg',
        title: {
            tr: 'Yeni Doğan',
            en: 'Newborn'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yeni-dogan/0.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/1.jpeg',
                img3: '/images/ev-giyim/product/yeni-dogan/2.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yeni-dogan/3.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/4.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yeni-dogan/5.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/6.jpeg',
                img3: '/images/ev-giyim/product/yeni-dogan/7.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yeni-dogan/8.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/9.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yeni-dogan/10.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/11.jpeg',
                img3: '/images/ev-giyim/product/yeni-dogan/12.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yeni-dogan/13.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/16.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yeni-dogan/14.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/15.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yeni-dogan/17.jpeg',
                img2: '/images/ev-giyim/product/yeni-dogan/18.jpeg',
            }
        ]
    },
    {
        product: 'gecelik-sabahlik',
        category: 'yetiskin',
        banner: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/banner.jpg',
        title: {
            tr: 'Gecelik Sabahlık',
            en: 'Nightgown & Robe'
        },
        data: [
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/0.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/1.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/2.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/3.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/4.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/5.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/6.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/7.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/8.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/9.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/10.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/11.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/12.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/13.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/14.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gecelik-sabahlik/15.jpeg',
            }
        ]
    },
    {
        product: 'gunluk-giyim',
        category: 'yetiskin',
        banner: '/images/ev-giyim/product/yetiskin/gunluk-giyim/banner.jpg',
        title: {
            tr: 'Günlük Giyim',
            en: 'Casual Wear'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/0.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/1.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/2.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/27.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/28.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/29.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/3.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/4.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/5.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/30.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/31.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/6.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/7.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/8.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gunluk-giyim/9.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/24.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/10.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/11.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/12.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/13.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/14.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/15.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/40.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/41.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/42.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/16.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/17.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/18.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gunluk-giyim/19.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/32.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/33.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/34.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/35.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/36.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/20.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/21.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/43.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/44.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/45.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/39.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/37.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/gunluk-giyim/38.jpeg',
                img4: '/images/ev-giyim/product/yetiskin/gunluk-giyim/22.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/gunluk-giyim/25.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/gunluk-giyim/26.jpeg',
            }
        ]
    },
    {
        product: 'pijama',
        category: 'yetiskin',
        banner: '/images/ev-giyim/product/yetiskin/pijama/banner.jpg',
        title: {
            tr: 'Pijama',
            en: 'Pajamas'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/pijama/0.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/pijama/1.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/pijama/2.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/pijama/7.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/pijama/8.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/pijama/9.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/pijama/3.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/pijama/4.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-giyim/product/yetiskin/pijama/10.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-giyim/product/yetiskin/pijama/5.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/pijama/6.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-giyim/product/yetiskin/pijama/11.jpeg',
                img2: '/images/ev-giyim/product/yetiskin/pijama/12.jpeg',
                img3: '/images/ev-giyim/product/yetiskin/pijama/13.jpeg',
            }
        ]
    }
];
