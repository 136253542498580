
import { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
const sertifikalarData = [
    {
        url: '/images/sertifikalar/1.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/2.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/3.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/4.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/5.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/6.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/7.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/8.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/9.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/10.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/11.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/12.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/13.png',
        name: 'z'
    },
    {
        url: '/images/sertifikalar/14.png',
        name: 'z'
    },
]
const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 20%)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%)`;

const variants = {
    visible: index => ({
        opacity: 1,
        scale: 1,
        transition: {
            delay: index * 0.1,
        },
    }),
    hidden: { opacity: 0, scale: 0.8 },
}


const Sertifikalar = () => {
    const { t, i18n } = useTranslation();
    const controls = useAnimation();
    const [isLoaded, setIsLoaded] = useState(true);
    const [isInView, setIsInView] = useState(false);
    return (
        <div>
            <motion.div
                initial={false}
                animate={
                    isLoaded && isInView
                        ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: isInView ? 0.7 : 0 }}

                onViewportEnter={() => setIsInView(true)}
            >
                <div className='grid  grid-cols-9 md:grid-cols-12 bg-black ' >
                    <div className='md:col-start-4 md:col-end-10 col-start-2 col-end-9'>
                        <div className=' w-full  flex flex-col items-center justify-center text-[#D8D8D8] py-20'>
                            <motion.h1 initial={false}
                                animate={isLoaded && isInView && isInView ? { width: 'auto', opacity: 1 } : { width: '0', opacity: 0 }}
                                transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 0.7 : 0 }} className=' responsive-70-50 tracking-widest mb-5 font-medium overflow-hidden'>{t('sertifikalar')}</motion.h1>
                            {/* <motion.p initial={false}
                                animate={isLoaded && isInView && isInView ? { opacity: 1 } : { opacity: 0 }}
                                transition={{ duration: isInView ? 0.7 : 0, delay: isInView ? 0.7 : 0 }} className='text-14 text-center font-light'>Turkuaz Tekstil Nihat ZEYBEKCİ tarafından 1994 yılında kurularak, havlu ve bornoz imalatına başladı. İmalatta ise bukle dokuma ve dokunan ürünlerin konfeksiyon tarafından işlenme süreçleri yer aldı. Artan talebi karşılamak ve tüm üretim süreçlerini kendi kontrolü altına almak amacıyla Turkuaz Tekstil, 2005 yılında en son teknoloji ve ekipman ile donatılmış kendi nakış işletmesi Turkar Tekstil Sanayi ve Ticaret A.Ş.’yi kurdu.</motion.p> */}
                        </div>
                    </div>
                </div>
            </motion.div>





            <motion.div

                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7, duration: 0.5 }}
                onAnimationComplete={() => controls.start("visible")} className='grid md:grid-cols-12 grid-cols-9 py-20'>
                <div className='col-start-2 col-end-9 md:col-start-3 md:col-end-11 grid grid-cols-2 md:grid-cols-6  w-full gap-5'>
                    {
                        sertifikalarData.map((item, index) => {
                            return (
                                <motion.img
                                    custom={index}
                                    animate={controls}

                                    initial="hidden"
                                    variants={variants}

                                    key={index} src={item.url}

                                    className="w-full  "
                                    alt={item.name} />
                            )
                        })
                    }


                </div>
            </motion.div>
        </div>
    )
}

export default Sertifikalar