import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css'; // make sure to import the styles
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

const CustomSingleGallery = ({mainImage}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = () => {
    setIsOpen(true);
  };

  return (
    <div onClick={handleImageClick} className='h-full w-full'>
      <img
        src={mainImage}
        className='w-full transition h-full object-cover'
        alt='Gallery Image'
      />

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={[{ src: mainImage, alt: 'Gallery Image' }]}
          plugins={[Zoom]}
        />
      )}
    </div>
  );
};

export default CustomSingleGallery;
