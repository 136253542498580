import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Iletisim = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            iletisimSecenek: 'tr'
        }
    });
    const [selectedOption, setSelectedOption] = useState('tr');

    const onSubmit = (data) => {
        console.log(data);
        // Burada form verilerini işleyebilirsiniz, örneğin bir API'ye gönderebilirsiniz.
    };

    const handleNumberInput = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        e.target.value = value;
    };

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div className=' w-full grid grid-cols-9 pt-20 pb-96'>
            <div className='col-start-2 col-end-9 grid grid-cols-1 md:grid-cols-2 gap-10'>
                <div className='h-full w-full  order-2 md:!order-1'>
                    <h5 className='text-35 font-medium'>{t('iletisim-header')}</h5>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4457.111554208331!2d29.06619782102048!3d37.821577197607546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c74040278ce271%3A0x3466df427003da39!2sturquoise%20textile!5e0!3m2!1sen!2str!4v1721737956915!5m2!1sen!2str"
                        width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                    <a className='block mt-5'>{t('iletisim-adres')}</a>
                    <a href='mailto:turkuaz@turkuaztex.com' className='block mt-5'>turkuaz@turkuaztex.com</a>
                    <a href='https://maps.app.goo.gl/jCoZpL8S4NgH1JM16' target='_blank' className='text-18 py-2 px-6 bg-turkuazGreen font-medium text-white mt-5 inline-flex justify-center space-x-2' >
                        <h5>{t('iletisim-yol-tarifi')}</h5> 
                        <img src='/images/icons/kesfet-right.svg' className='w-5'/>    
                    </a>

                </div>
                <div className='h-full w-full md:w-4/5 order-1 md:!order-2 mb-20 lg:mb-0'>
                    <h5 className='text-35 font-medium'>{t('iletisim-detaylar')}</h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col mb-5'>
                          
                            <input
                                id="isim"
                                placeholder={t('iletisim-isim-placeholder')}
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                {...register('isim', { required: t('iletisim-isim-error') })}
                            />
                            {errors.isim && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.isim.message}</p>}
                        </div>

                        <div className='flex flex-col  mb-5'>
                          
                            <input
                                id="soyad"
                                placeholder={t('iletisim-soyad-placeholder')}
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                {...register('soyad', { required: t('iletisim-isim-error') })}
                            />
                            {errors.soyad && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.soyad.message}</p>}
                        </div>

                        <div className='flex flex-col  mb-5'>
                          
                            <input
                                id="gorev"
                                placeholder={t('iletisim-gorev-placeholder')}
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                {...register('gorev', { required: t('iletisim-isim-error') })}
                            />
                            {errors.gorev && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.gorev.message}</p>}
                        </div>

                        <div className='flex flex-col  mb-5'>
                           
                            <input
                                id="telefon"
                                type="tel"
                                placeholder={t('iletisim-telefon-placeholder')}
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                onInput={handleNumberInput}
                                {...register('telefon', {
                                    required: t('iletisim-telefon-error'),
                                    pattern: {
                                        value: /^[0-9]{10,11}$/,
                                        message: t('iletisim-telefon-pattern-error')
                                    }
                                })}
                            />
                            {errors.telefon && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.telefon.message}</p>}
                        </div>

                        <div className='flex flex-col  mb-5'>
                           
                            <input
                                id="email"
                                type="email"
                                placeholder="E-posta*"
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                {...register('email', {
                                    required: 'E-posta zorunludur',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Geçerli bir e-posta adresi girin'
                                    }
                                })}
                            />
                            {errors.email && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.email.message}</p>}
                        </div>

                        <div className='flex flex-col  mb-5'>
                          
                            <textarea
                                id="mesaj"
                                placeholder={t('iletisim-iletmek-label')}
                                className='bg-[#EBEAEA] text-16 px-4 py-2'
                                {...register('mesaj', { required: 'Mesaj zorunludur' })}
                            ></textarea>
                            {errors.mesaj && <p className='text-red-500 text-right text-16 opacity-50 '>{errors.mesaj.message}</p>}
                        </div>

                        <div className='flex flex-col mb-1 mt-5 pl-5'>
                            <label className='mb-2 text-16 text-[#ACACAC]'>{t('iletisim-choose')}</label>
                            <div className='flex'>
                                <div className={`relative h-6 w-6 ${selectedOption === 'tr' ? 'bg-turkuazGreen text-white' : 'bg-white text-black'} flex justify-center items-center`}>
                                    <h5 className='text-16 '>TR</h5>
                                    <input
                                        type="radio"
                                        id="tr"
                                        value="tr"
                                        className='h-full w-full absolute top-0 left-0 opacity-0'
                                        {...register('iletisimSecenek', { required: 'Bir iletişim seçeneği seçmelisiniz' })}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                                <div className={`relative h-6 w-6 ${selectedOption === 'en' ? 'bg-turkuazGreen text-white' : 'bg-white text-black'} flex justify-center items-center`}>
                                    <h5 className='text-16 '>EN</h5>
                                    <input
                                        type="radio"
                                        id="en"
                                        value="en"
                                        className='h-full w-full absolute top-0 left-0 opacity-0'
                                        {...register('iletisimSecenek', { required: 'Bir iletişim seçeneği seçmelisiniz' })}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                                <div className={`relative h-6 w-6 ${selectedOption === 'de' ? 'bg-turkuazGreen text-white' : 'bg-white text-black'} flex justify-center items-center`}>
                                    <h5 className='text-16 '>DE</h5>
                                    <input
                                        type="radio"
                                        id="de"
                                        value="de"
                                        className='h-full w-full absolute top-0 left-0 opacity-0'
                                        {...register('iletisimSecenek', { required: 'Bir iletişim seçeneği seçmelisiniz' })}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                                <div className={`relative h-6 w-6 ${selectedOption === 'ca' ? 'bg-turkuazGreen text-white' : 'bg-white text-black'} flex justify-center items-center`}>
                                    <h5 className='text-16 '>FR</h5>
                                    <input
                                        type="radio"
                                        id="ca"
                                        value="ca"
                                        className='h-full w-full absolute top-0 left-0 opacity-0'
                                        {...register('iletisimSecenek', { required: 'Bir iletişim seçeneği seçmelisiniz' })}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                            </div>
                            {errors.iletisimSecenek && <p className='text-red-500 text-right text-16 opacity-50   text-16 '>{errors.iletisimSecenek.message}</p>}
                        </div>

                        <div className='space-x-2 mb-2'>
                            <input
                                type="checkbox"
                                id="kvkk"
                                {...register('kvkk', { required: t('iletisim-isim-error') })}
                            />
                            <label htmlFor="kvkk" className='text-16'>{t('iletisim-kvkk-1')}</label>
                            {errors.kvkk && <p className='text-red-500 text-right text-16 opacity-50 opa'>{errors.kvkk.message}</p>}
                        </div>

                        <div className='space-x-2'>
                            <input
                                type="checkbox"
                                id="kabul"
                                {...register('kabul')}
                            />
                            <label htmlFor="kabul" className='text-16'>{t('iletisim-kvkk-2')}</label>
                        </div>

                        <button type="submit" className='text-18 py-2 px-6 bg-turkuazGreen font-medium text-white mt-5'>{t('gonder')}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Iletisim;
