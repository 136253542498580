import React from 'react'
import { Outlet } from 'react-router-dom'
import BizdenHaberlerComponent from '../../../components/BizdenHaberlerComponent'


const BizdenHaberlerComponentDATA = [
  {
    name: {
      tr: 'PEMBE ŞEHİR PROJESİ İLE KADINLARIMIZIN YANINDAYIZ.',
      en: 'WE STAND BY OUR WOMEN WITH THE PINK CITY PROJECT.'
    },
    link: '0'
  },
  {
    name: {
      tr: 'ÜNLÜ MODACI HAKAN AKKAYA TURKUAZ TEKSTİL’DE',
      en: 'FAMOUS DESIGNER HAKAN AKKAYA AT TURKUAZ TEXTILE'
    },
    link: '1'
  },
  {
    name: {
      tr: 'TURKUAZ TEKSTİL EVTEKS 2020 FUARINDA',
      en: 'TURKUAZ TEXTILE AT EVTEKS 2020 FAIR'
    },
    link: '2'
  },
  {
    name: {
      tr: 'TURKUAZ TEKSTİL 15000M2 ALANA HATIRA ORMANI OLUŞTURDU',
      en: 'TURKUAZ TEXTILE CREATED A MEMORIAL FOREST IN A 15000M2 AREA'
    },
    link: '3'
  },
  {
    name: {
      tr: '7-10 OCAK 2020 HEIMTEXTIL FRANKFURT FUARINA KATILDIK.',
      en: 'WE PARTICIPATED IN HEIMTEXTIL FRANKFURT FAIR ON JANUARY 7-10, 2020.'
    },
    link: '4'
  },
];


const BizdenHaberler = () => {
  return (
    <>
      <div className='col-span-7 lg:col-span-2 '>

        <BizdenHaberlerComponent BizdenHaberlerComponentDATA={BizdenHaberlerComponentDATA}/>
      </div>
        <div className='col-span-7  '>
           <Outlet />
      </div>
    </>
  )
}

export default BizdenHaberler