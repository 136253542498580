import React from 'react'
import BizdenHaberlerComponent from '../../../components/BizdenHaberlerComponent'
import { Outlet } from 'react-router-dom'


const BizdenHaberlerComponentDATA =[
    {
        name: {
          tr: 'HEIMTEXTILE',
          en: 'HEIMTEXTILE'
        },
        link: '0'
    },
    {
      name: {
        tr: 'EVTEKS',
        en: 'EVTEKS'
      },
        link: '1'
    },
  ]

const Fuarlar = () => {
  return (
    <>
      <div className='col-span-2 '>

        <BizdenHaberlerComponent BizdenHaberlerComponentDATA={BizdenHaberlerComponentDATA}/>
      </div>
        <div className='col-span-7  '>
           <Outlet />
      </div>
    </>
  )
}

export default Fuarlar