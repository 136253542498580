import React from 'react';

const OneThree = ({ img1, img2, img3, img4, openLightbox }) => {
  return (
    <div className="w-full grid grid-cols-8 mb-10">
      <div className='col-start-2 col-end-8'>
        <div className="grid grid-cols-7 w-full h-full gap-5">
          <div className="col-span-3  flex max-h-[100vh]">
            <img
              src={img1}
              className='h-full w-full object-cover cursor-pointer'
              alt="Product"
              onClick={() => openLightbox(img1)}
            />
          </div>
          <div className="col-span-4  flex flex-col max-h-[100vh]">
            <img
              src={img2}
              className='w-full h-1/3 object-cover cursor-pointer pb-5'
              alt="Product"
              onClick={() => openLightbox(img2)}
            />
            <img
              src={img3}
              className='w-full h-1/3 object-cover cursor-pointer pb-5'
              alt="Product"
              onClick={() => openLightbox(img3)}
            />
            <img
              src={img4}
              className='w-full h-1/3 object-cover cursor-pointer'
              alt="Product"
              onClick={() => openLightbox(img4)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneThree;
