import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import OneTwo from '../components/products/OneTwo';
import OneFull from '../components/products/OneFull';
import ProductText from '../components/products/ProductText';
import OneThree from '../components/products/OneThree';
import OneOne from '../components/products/OneOne';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useTranslation } from 'react-i18next';

const Urunler = ({ DATA }) => {  // Assuming default language is Turkish
    const { id } = useParams();
    const location = useLocation();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; // Get the current language
    console.log(location);

    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const pathParts = location.pathname.split('/');
    const categoryFromPath = pathParts[3];
    const productIdFromPath = pathParts[4];

    const product = DATA.find(p => p.category === categoryFromPath && (p.product === productIdFromPath || p.product === ''));

    if (!product) {
        return <div>Product not found</div>;
    }

    const images = product.data.flatMap(item => {
        const baseImages = [];
        if (item.img1) baseImages.push(item.img1);
        if (item.img2) baseImages.push(item.img2);
        if (item.img3) baseImages.push(item.img3);
        if (item.img4) baseImages.push(item.img4);
        return baseImages;
    });

    const openLightbox = (img) => {
        const index = images.findIndex(image => image === img);

        if (index !== -1) {
            setCurrentIndex(index);
            setIsOpen(true);
        } else {
            console.error("Image not found in the images array");
        }
    };

    return (
        <div className="overflow-x-hidden">
            <div className='relative h-[50vh] mb-20'>
                <img
                    src={product.banner}
                    className="h-full w-full object-cover"
                    alt="Banner"
                />
                <div className='absolute h-full w-full top-0 left-0 flex items-end'>
                    <div className='w-full grid grid-cols-8 py-4 lg:py-10'>
                        <div className='col-start-2 col-end-8 grid grid-cols-2 gap-5 responsive-50-35 text-white uppercase font-extralight'>
                            {product.title[currentLanguage]}
                        </div>
                    </div>
                </div>
            </div>
            {product.data.map((item, index) => {
                switch (item.type) {
                    case 'one-two':
                        return (
                            <OneTwo
                                key={index}
                                img1={item.img1}
                                img2={item.img2}
                                img3={item.img3}
                                openLightbox={openLightbox}
                            />
                        );
                    case 'one-full':
                        return (
                            <OneFull
                                key={index}
                                img1={item.img1}
                                openLightbox={openLightbox}
                            />
                        );
                    case 'one-three':
                        return (
                            <OneThree
                                key={index}
                                img1={item.img1}
                                img2={item.img2}
                                img3={item.img3}
                                img4={item.img4}
                                openLightbox={openLightbox}
                            />
                        );
                    case 'one-one':
                        return (
                            <OneOne
                                key={index}
                                img1={item.img1}
                                img2={item.img2}
                                openLightbox={openLightbox}
                            />
                        );
                    default:
                        return null;
                }
            })}
            <Lightbox
                open={isOpen}
                close={() => setIsOpen(false)}
                slides={images.map(src => ({ src }))}
                index={currentIndex}
                plugins={[Zoom]}
            />
        </div>
    );
};

export default Urunler;
