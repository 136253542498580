import React from 'react'
import { useParams } from 'react-router-dom'
import CustomGallery from '../../../components/Gallery/CustomGallery'
import { useTranslation } from 'react-i18next'

const fuarlarDATA = [
    {
        id: 0,
        title: 'HEIMTEXTILE',
        text: {
          tr: 'Turkuaz Tekstil, Almanya\'nın Frankfurt kentinde düzenlenen ve dünyanın en büyük uluslararası ev tekstili fuarı olan Heimtextil’e, 1996 yılından itibaren, her sene katılarak, kolleksiyonları ile büyük beğeni topluyor, ürünlerini uluslararası bir ortamda sergileme imkanı buluyor.',
          en: 'Turkuaz Textile has been participating in Heimtextil, the world\'s largest international home textiles fair held in Frankfurt, Germany, every year since 1996, garnering great acclaim with its collections and showcasing its products in an international environment.'
      },
        imageData:[
            {
                id: 0,
                image: '/images/basin/fuarlar/Heimtextil2018-1.jpg',
                alt: '',
              },
              {
                id: 1,
                image: '/images/basin/fuarlar/Heimtextil2018-2.jpg',
                alt: '',
              },
              {
                id: 2,
                image: '/images/basin/fuarlar/Heimtextil2018-4.jpg',
                alt: '',
              },
              {
                id: 3,
                image: '/images/basin/fuarlar/Heimtextil2018-5.jpg',
                alt: '',
              },
              {
                id: 4,
                image: '/images/basin/fuarlar/Heimtextil2019-4.jpg',
                alt: '',
              },
              {
                id: 5,
                image: '/images/basin/fuarlar/Heimtextil2019-5.jpg',
                alt: '',
              },
              {
                id: 6,
                image: '/images/basin/fuarlar/Heimtextil2019-6.jpg',
                alt: '',
              },
              {
                id: 7,
                image: '/images/basin/fuarlar/Heimtextil2019-7.jpg',
                alt: '',
              },
              {
                id: 8,
                image: '/images/basin/fuarlar/Heimtextil2019-8.jpg',
                alt: '',
              },
              {
                id: 9,
                image: '/images/basin/fuarlar/Heimtextil2019-9.jpg',
                alt: '',
              },
        ]
    },
    {
        id: 1,
        title: 'EVTEKS',
        text: {
          tr: 'Türkiye’de ev tekstilinin öncü ismi Turkuaz Tekstil, 2001 yılından itibaren, tüm yeniliklerinin sergilendiği Evteks Fuarı’na her sene katılarak, sektörde yerini alıyor.',
          en: 'As a leading name in home textiles in Turkey, Turkuaz Textile has been participating in the Evteks Fair, where all its innovations are showcased, every year since 2001, securing its place in the industry.'
      },
        imageData:[
            {
                id: 0,
                image: '/images/basin/fuarlar/Heimtextil2019-1.jpg',
                alt: '',
              },
              {
                id: 1,
                image: '/images/basin/fuarlar/Heimtextil2019-2.jpg',
                alt: '',
              },
              {
                id: 2,
                image: '/images/basin/fuarlar/Heimtextil2019-3.jpg',
                alt: '',
              },
              {
                id: 3,
                image: '/images/basin/fuarlar/evteks2018-1.jpg',
                alt: '',
              },
              {
                id: 4,
                image: '/images/basin/fuarlar/evteks2018-2.jpg',
                alt: '',
              },
              {
                id: 5,
                image: '/images/basin/fuarlar/evteks2018-3.jpg',
                alt: '',
              },
              {
                id: 6,
                image: '/images/basin/fuarlar/evteks2018-4.jpg',
                alt: '',
              },
              {
                id: 7,
                image: '/images/basin/fuarlar/evteks2018-5.jpg',
                alt: '',
              },
        ]
    },

]

const FuarlarInner = () => {
  const { i18n } = useTranslation(); // Dil yönetimi için i18n kullanıyoruz
    const { id } = useParams()
    const numericId = Number(id)


    const filteredData = fuarlarDATA.find(item => item.id === numericId)




    if (!filteredData) {
        return <p>Data not found</p>
    }

    const { title, text, imageData } = filteredData
  return (
    <div className='w-full  '>
    {
filteredData&&
        <div className=' '>
            <h5 className='text-35 mb-10'>{title}</h5>
       

                <div className='col-span-2'>
                    <p>{text[i18n.language]}</p>
                </div>
    

     
            <h5 className='text-22 my-5'>GALERİ</h5>
            <CustomGallery  imageData={imageData}/>
        </div>

    }
</div>
  )
}

export default FuarlarInner