import React from 'react';

const OneFull = ({ img1, openLightbox }) => {
  return (
    <div className='w-full grid grid-cols-8 mb-10'>
      <div className='col-start-2 col-end-8 max-h-[60vh]'>
        <img
          src={img1}
          className='h-full w-full object-cover cursor-pointer'
          alt="Product"
          onClick={() => openLightbox(img1)}
        />
      </div>
    </div>
  );
};

export default OneFull;
