export const HomeSliderData = [
    {
        image: '/images/ev-giyim/hero-slider/5.jpg',
        title: { tr: 'GECELİK/SABAHLIK', en: 'NIGHTGOWN/ROBE' },
        category: { tr: 'YETİŞKİN', en: 'ADULT' },
        link: '/ev-giyim/urunler/yetiskin/gecelik-sabahlik'
    },
    {
        image: '/images/ev-giyim/hero-slider/6.jpg',
        title: { tr: 'GÜNLÜK GİYİM', en: 'CASUAL WEAR' },
        category: { tr: 'YETİŞKİN', en: 'ADULT' },
        link: '/ev-giyim/urunler/yetiskin/gunluk-giyim'
    },
    {
        image: '/images/ev-giyim/hero-slider/7.jpg',
        title: { tr: 'PİJAMA', en: 'PAJAMAS' },
        category: { tr: 'YETİŞKİN', en: 'ADULT' },
        link: '/ev-giyim/urunler/yetiskin/pijama'
    },
    {
        image: '/images/ev-giyim/hero-slider/4.jpg',
        title: { tr: 'YENİ DOĞAN', en: 'NEWBORN' },
        category: { tr: 'BEBEK GRUBU', en: 'BABY GROUP' },
        link: '/ev-giyim/urunler/yeni-dogan'
    },
    {
        image: '/images/ev-giyim/hero-slider/8.jpg',
        title: { tr: 'ÇOCUK', en: 'CHILD' },
        category: { tr: 'ÇOCUK GRUBU', en: 'CHILDREN GROUP' },
        link: '/ev-giyim/urunler/cocuk/cocuk'
    },
    {
        image: '/images/ev-giyim/hero-slider/0.jpg',
        title: { tr: 'UYKU TULUM', en: 'SLEEPING BAG' },
        category: { tr: 'AKSESUAR', en: 'ACCESSORY' },
        link: '/ev-giyim/urunler/aksesuar/uyku-tulumu'
    },

    {
        image: '/images/ev-giyim/hero-slider/1.jpg',
        title: { tr: 'YATAK', en: 'BED' },
        category: { tr: 'AKSESUAR', en: 'ACCESSORY' },
        link: '/ev-giyim/urunler/aksesuar/yatak-grubu'
    },
    // {
    //     image: '/images/ev-giyim/hero-slider/2.jpg',
    //     title: { tr: 'AKSESUAR', en: 'ACCESSORY' },
    //     category: { tr: 'ÇOCUK GRUBU', en: 'CHILDREN GROUP' },
    //     link: '/ev-giyim/urunler/cocuk/aksesuar'
    // },



    {
        image: '/images/ev-giyim/hero-slider/3.jpg',
        title: { tr: 'OYUN', en: 'PLAY' },
        category: { tr: 'ÇOCUK GRUBU', en: 'CHILDREN GROUP' },
        link: '/ev-giyim/urunler/cocuk/oyun-grubu'
    },


    {
        image: '/images/ev-giyim/hero-slider/9.jpg',
        title: { tr: '', en: '' },
        category: { tr: '', en: '' },
        link: ''
    },

];
