import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';



const CustomGallery = ({imageData}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      {imageData.map((dataItem, index) => (
        <div
          key={dataItem.id}
          onClick={() => handleImageClick(index)}
          className="relative group overflow-hidden cursor-pointer flex items-center justify-center hover:scale-95 transition-all duration-1000 ease-in-out"
        >
          <img
            src={dataItem.image}
            alt={dataItem.alt}
            className="w-full h-96 object-cover transition duration-500 scale-105 group-hover:scale-100"
          />
        </div>
      ))}

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={imageData.map((dataItem) => ({
            src: dataItem.image,
            alt: dataItem.alt,
          }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}
    </div>
  );
};

export default CustomGallery;
