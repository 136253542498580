export const DATA = [
    {
        product: '',
        category: 'cocuk-grubu',
        banner: '/images/ev-tekstil/product/cocuk-grubu/banner.jpg',
        title: {
            tr: 'Çocuk Grubu',
            en: 'Kids Group'
        },
        data: [
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/cocuk-grubu/22.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/23.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/24.jpeg',
                img4: '/images/ev-tekstil/product/cocuk-grubu/25.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/cocuk-grubu/0.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/1.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/2.jpeg',
                img4: '/images/ev-tekstil/product/cocuk-grubu/3.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/cocuk-grubu/9.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/10.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/11.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/cocuk-grubu/4.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/5.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/6.jpeg',
                img4: '/images/ev-tekstil/product/cocuk-grubu/7.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/cocuk-grubu/12.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/13.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/14.jpeg',
                img4: '/images/ev-tekstil/product/cocuk-grubu/15.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-tekstil/product/cocuk-grubu/16.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/cocuk-grubu/17.jpeg',
                img2: '/images/ev-tekstil/product/cocuk-grubu/18.jpeg',
                img3: '/images/ev-tekstil/product/cocuk-grubu/19.jpeg',
                img4: '/images/ev-tekstil/product/cocuk-grubu/20.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-tekstil/product/cocuk-grubu/21.jpeg',
            },
        ]
    },
    {
        product: '',
        category: 'bornoz-grubu',
        banner: '/images/ev-tekstil/product/bornoz-grubu/banner.jpg',
        title: {
            tr: 'Bornoz Grubu',
            en: 'Bathrobe Group'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/bornoz-grubu/0.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/1.jpeg',
                img3: '/images/ev-tekstil/product/bornoz-grubu/2.jpeg'
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/bornoz-grubu/3.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/4.jpeg',
                img3: '/images/ev-tekstil/product/bornoz-grubu/5.jpeg',
                img4: '/images/ev-tekstil/product/bornoz-grubu/6.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/bornoz-grubu/7.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/8.jpeg',
                img3: '/images/ev-tekstil/product/bornoz-grubu/9.jpeg'
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/bornoz-grubu/10.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/11.jpeg',
                img3: '/images/ev-tekstil/product/bornoz-grubu/12.jpeg',
                img4: '/images/ev-tekstil/product/bornoz-grubu/13.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/bornoz-grubu/18.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/19.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/bornoz-grubu/14.jpeg',
                img2: '/images/ev-tekstil/product/bornoz-grubu/15.jpeg',
                img3: '/images/ev-tekstil/product/bornoz-grubu/16.jpeg',
                img4: '/images/ev-tekstil/product/bornoz-grubu/17.jpeg',
            },
        ]
    },
    {
        product: 'otel',
        category: 'banyo-grubu',
        banner: '/images/ev-tekstil/product/banyo-grubu/otel/banner.jpg',
        title: {
            tr: 'Otel',
            en: 'Hotel'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/otel/0.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/otel/1.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/banyo-grubu/otel/6.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/otel/7.jpeg',
                img3: '/images/ev-tekstil/product/banyo-grubu/otel/8.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/otel/2.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/otel/3.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/otel/4.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/otel/5.jpeg',
            },
        ]
    },
    {
        product: 'promosyon',
        category: 'banyo-grubu',
        banner: '/images/ev-tekstil/product/banyo-grubu/promosyon/banner.jpg',
        title: {
            tr: 'Promosyon',
            en: 'Promotion'
        },
        data: [
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/banyo-grubu/promosyon/0.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/promosyon/1.jpeg',
                img3: '/images/ev-tekstil/product/banyo-grubu/promosyon/2.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/promosyon/3.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/promosyon/4.jpeg',
            },
        ]
    },
    {
        product: 'pas-pas',
        category: 'banyo-grubu',
        banner: '/images/ev-tekstil/product/banyo-grubu/pas-pas/banner.jpg',
        title: {
            tr: 'Pas Pas',
            en: 'Bath Mat'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/pas-pas/0.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/pas-pas/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/pas-pas/2.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/pas-pas/3.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/pas-pas/4.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/pas-pas/5.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/pas-pas/6.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/pas-pas/7.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/banyo-grubu/pas-pas/8.jpeg',
                img2: '/images/ev-tekstil/product/banyo-grubu/pas-pas/9.jpeg',
            },
        ]
    },
    {
        product: '',
        category: 'havlu-grubu',
        banner: '/images/ev-tekstil/product/havlu-grubu/banner.jpg',
        title: {
            tr: 'Havlu Grubu',
            en: 'Towel Group'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/0.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/1.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/2.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/3.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/4.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/5.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/6.jpeg',
            },
            {
                type: 'one-full',
                img1: '/images/ev-tekstil/product/havlu-grubu/14.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/7.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/8.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/9.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/10.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/11.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/12.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/13.jpeg'
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/15.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/16.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/17.jpeg'
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/18.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/19.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/20.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/21.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/22.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/23.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/24.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/25.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/26.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/27.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/28.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/29.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/30.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/31.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/32.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/33.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/34.jpeg'
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/35.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/36.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/37.jpeg'
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/38.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/39.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/40.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/41.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/42.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/45.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/43.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/44.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/46.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/47.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/48.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/49.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/50.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/51.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/52.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/53.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/54.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/55.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/56.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/57.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/58.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/59.jpeg'
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/60.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/61.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/62.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/63.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/64.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/65.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/67.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/66.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/68.jpeg'
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/havlu-grubu/72.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/70.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/71.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/73.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/69.jpeg',
            },
            {
                type: 'one-three',
                img1: '/images/ev-tekstil/product/havlu-grubu/76.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/74.jpeg',
                img3: '/images/ev-tekstil/product/havlu-grubu/75.jpeg',
                img4: '/images/ev-tekstil/product/havlu-grubu/77.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/78.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/80.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/78.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/80.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/82.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/83.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/84.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/85.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/86.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/87.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/88.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/89.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/90.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/91.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/92.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/93.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/havlu-grubu/94.jpeg',
                img2: '/images/ev-tekstil/product/havlu-grubu/95.jpeg',
            },
        ]
    },
    {
        product: '',
        category: 'mutfak-grubu',
        title: {
            tr: 'Mutfak Grubu',
            en: 'Kitchen Group'
        },
        banner: '/images/ev-tekstil/product/mutfak-grubu/banner.jpg',
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/mutfak-grubu/0.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/mutfak-grubu/2.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/3.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/mutfak-grubu/4.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/5.jpeg',
                img3: '/images/ev-tekstil/product/mutfak-grubu/6.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/mutfak-grubu/7.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/8.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/mutfak-grubu/9.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/10.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/mutfak-grubu/11.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/12.jpeg',
                img3: '/images/ev-tekstil/product/mutfak-grubu/13.jpeg'
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/mutfak-grubu/14.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/15.jpeg',
                img3: '/images/ev-tekstil/product/mutfak-grubu/16.jpeg'
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/mutfak-grubu/17.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/18.jpeg',
            },
            {
                type: 'one-two',
                img1: '/images/ev-tekstil/product/mutfak-grubu/21.jpeg',
                img2: '/images/ev-tekstil/product/mutfak-grubu/19.jpeg',
                img3: '/images/ev-tekstil/product/mutfak-grubu/20.jpeg'
            },
        ]
    },
    {
        product: '',
        category: 'pestemal',
        banner: '/images/ev-tekstil/product/pestemal/banner.jpg',
        title: {
            tr: 'Peştemal',
            en: 'Pestemal'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/pestemal/0.jpeg',
                img2: '/images/ev-tekstil/product/pestemal/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/pestemal/2.jpeg',
                img2: '/images/ev-tekstil/product/pestemal/3.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/pestemal/4.jpeg',
                img2: '/images/ev-tekstil/product/pestemal/5.jpeg',
            },
        ]
    },
    {
        product: '',
        category: 'plaj',
        banner: '/images/ev-tekstil/product/plaj/banner.jpg',
        title: {
            tr: 'Plaj',
            en: 'Beach'
        },
        data: [
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/plaj/0.jpeg',
                img2: '/images/ev-tekstil/product/plaj/1.jpeg',
            },
            {
                type: 'one-one',
                img1: '/images/ev-tekstil/product/plaj/2.jpeg',
                img2: '/images/ev-tekstil/product/plaj/3.jpeg',
            },
        ]
    },
];
