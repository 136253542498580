

import { motion } from 'framer-motion'


import DeskopHeader from './DeskopHeader'
import { useEffect, useState } from 'react'
import MobileHeader from './MobileHeader'
import { Link, useLocation, useParams } from 'react-router-dom'
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'


const DATA = [
  {
    title: { tr: 'ANASAYFA', en: 'HOME' },
    link: '/'
  },
  {
    title: { tr: 'ÜRÜNLER', en: 'PRODUCTS' },
    dropDown: [
      {
        title: { tr: 'Ev Tekstili', en: 'Home Textile' },
        dropDown: [
          {
            title: { tr: 'Havlu Grubu', en: 'Towel Group' },
            link: '/ev-tekstil/urunler/havlu-grubu',
          },
          {
            title: { tr: 'Bornoz Grubu', en: 'Bathrobe Group' },
            link: '/ev-tekstil/urunler/bornoz-grubu',
          },
          {
            title: { tr: 'Banyo Grubu', en: 'Bathroom Group' },
            dropDown: [
              {
                title: { tr: 'Pas Pas', en: 'Bath Mat' },
                link: '/ev-tekstil/urunler/banyo-grubu/pas-pas',
              },
              {
                title: { tr: 'Promosyon', en: 'Promotional' },
                link: '/ev-tekstil/urunler/banyo-grubu/promosyon',
              },
              {
                title: { tr: 'Otel', en: 'Hotel' },
                link: '/ev-tekstil/urunler/banyo-grubu/otel',
              },
            ]
          },
          {
            title: { tr: 'Mutfak Grubu', en: 'Kitchen Group' },
            link: '/ev-tekstil/urunler/mutfak-grubu',
          },
          {
            title: { tr: 'Çocuk Grubu', en: 'Kids Group' },
            link: '/ev-tekstil/urunler/cocuk-grubu',
          },
          {
            title: { tr: 'Plaj', en: 'Beach' },
            link: '/ev-tekstil/urunler/plaj',
          },
          {
            title: { tr: 'Peştamal', en: 'Pestemal' },
            link: '/ev-tekstil/urunler/pestemal',
          },
        ]
      },
      {
        title: { tr: 'Ev Giyim', en: 'Home Wear' },
        dropDown: [
          {
            title: { tr: 'Aksesuar', en: 'Accessory' },
            dropDown: [
              {
                title: { tr: 'Uyku Tulumu', en: 'Sleeping Bag' },
                link: '/ev-giyim/urunler/aksesuar/uyku-tulumu',
              },
              {
                title: { tr: 'Yatak Grubu', en: 'Bed Group' },
                link: '/ev-giyim/urunler/aksesuar/yatak-grubu',
              },
            ]
          },
          {
            title: { tr: 'Çocuk', en: 'Kids' },
            dropDown: [
              // {
              //   title: { tr: 'Aksesuar', en: 'Accessory' },
              //   link: '/ev-giyim/urunler/cocuk/aksesuar',
              // },
              {
                title: { tr: 'Çocuk', en: 'Child' },
                link: '/ev-giyim/urunler/cocuk/cocuk',
              },
              {
                title: { tr: 'Oyun Grubu', en: 'Play Group' },
                link: '/ev-giyim/urunler/cocuk/oyun-grubu',
              },
            ]
          },
          {
            title: { tr: 'Yeni Doğan', en: 'Newborn' },
            link: '/ev-giyim/urunler/yeni-dogan',
          },
          {
            title: { tr: 'Yetişkin', en: 'Adult' },
            dropDown: [
              {
                title: { tr: 'Gecelik - Sabahlık', en: 'Nightgown - Robe' },
                link: '/ev-giyim/urunler/yetiskin/gecelik-sabahlik',
              },
              {
                title: { tr: 'Günlük - Giyim', en: 'Casual Wear' },
                link: '/ev-giyim/urunler/yetiskin/gunluk-giyim',
              },
              {
                title: { tr: 'Pijama', en: 'Pajamas' },
                link: '/ev-giyim/urunler/yetiskin/pijama',
              },
            ]
          },
        ]
      },
    ]
  },
  {
    title: { tr: 'KURUMSAL', en: 'CORPORATE' },
    link: '/kurumsal',
  },
  {
    title: { tr: 'MARKALAR', en: 'BRANDS' },
    link: '/markalar',
  },
  {
    title: { tr: 'SERTİFİKALAR', en: 'CERTIFICATES' },
    link: '/sertifikalar',
  },
  {
    title: { tr: 'BASIN ODASI', en: 'PRESS ROOM' },
    dropDown: [
      {
        title: { tr: 'Bizden Haberler', en: 'News from Us' },
        link: '/basin-odasi/bizden-haberler',
      },
      {
        title: { tr: 'Fuarlar', en: 'Fairs' },
        link: '/basin-odasi/fuarlar',
      },
      {
        title: { tr: 'Kataloglar', en: 'Catalogs' },
        link: '/basin-odasi/kataloglar',
      },
      {
        title: { tr: 'Videolar', en: 'Videos' },
        link: '/basin-odasi/videolar',
      },
    ]
  },
  {
    title: { tr: 'İLETİŞİM', en: 'CONTACT' },
    link: '/iletisim'
  },
];


const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const isAbsolute = pathname.includes('ev-giyim') || pathname.includes('ev-tekstil');

  const { t } = useTranslation();
  const currentLanguage = i18n.language; // Get the current language

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    
        
   
   
  };



  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(false)

  }, [location])

  console.log(location)
  if (location.pathname === '/') {
    return null;
  }
  return (
    <div className={`sticky ${isAbsolute ? 'lg:absolute' : 'lg:block'} md:block top-0 bg-white lg:bg-transparent z-50 py-2 w-full`}>
      <div className=" w-full  grid grid-cols-9  ">
        <div className="col-start-2 col-end-9 flex flex-col">
          <div className=" flex justify-between">

            <Link to={'/'} className='block'><img src='/images/logo/logo.svg' className='h-14 lg:h-20' /></Link>
            {/* <a href='/pdf/kataloglar/e-katalog-en.pdf' target='_blank' className=" items-center space-x-2 hidden lg:flex ">
              <img className="h-7" src="/images/logo/catalog.svg" />
              <h5 className="text-14">{t('katalog')}</h5>
            </a> */}

            <div className="hidden lg:flex items-center text-16 space-x-2">
              <div
                className={`p-1 cursor-pointer ${currentLanguage === 'tr' ? 'border text-[#e5e7eb]' : ''}`}
              onClick={() => changeLanguage('tr')}
              >
                TR
              </div>
              <div
                className={`p-1 cursor-pointer ${currentLanguage === 'en' ? 'border text-[#e5e7eb]' : ''}`}
              onClick={() => changeLanguage('en')}
              >
                EN
              </div>
            </div>

            <div className='h-full w-10  flex lg:hidden justify-center items-center'>
              <div onClick={() => setToggle(!toggle)} className='w-full h-[1.6rem]  relative flex items-center cursor-pointer overflow-hidden'>
                <div className={`w-full h-0.5 bg-turkuazGreen absolute  origin-center transition-all duration-500 ${toggle ? 'top-3' : ' top-0'} ${toggle ? 'rotate-45' : ' rotate-0'}`}></div>
                <div className={`w-full h-0.5 bg-turkuazGreen absolute  origin-center transition-all duration-500 ${toggle ? 'top-3' : '  top-3'}  ${toggle ? 'translate-x-80' : ' translate-x-0'}`}></div>
                <div className={`w-full h-0.5 bg-turkuazGreen absolute  origin-center transition-all duration-500 ${toggle ? 'top-3' : ' top-6'} ${toggle ? '-rotate-45' : ' rotate-0'}`}></div>
              </div>
            </div>


          </div>

          {/* <DeskopHeader DATA={DATA}/> */}
          <MobileHeader DATA={DATA} toggle={toggle} />



        </div>
      </div>

    </div>

  )
}

export default Header