import React from 'react';

const OneTwo = ({ img1, img2, img3, openLightbox }) => {
  return (
    <div className='w-full grid grid-cols-8  mb-10'>
      <div className='col-start-2 col-end-8 grid grid-cols-2 gap-5  
      '>
        <div className='max-h-[60vh] '>
          <img
            src={img1}
            className='h-full w-full object-cover cursor-pointer'
            alt="Product"
            onClick={() => openLightbox(img1)}
          />
        </div>
        <div className=' max-h-[60vh]'>
          <div className='h-[58%] w-full object-cover'>
            <img
              src={img2}
              className='h-full w-full cursor-pointer object-cover'
              alt="Product"
              onClick={() => openLightbox(img2)}
            />
          </div>
          <div className='h-[42%] w-full pt-5 object-cover'>
            <img
              src={img3}
              className='h-full w-full object-cover cursor-pointer'
              alt="Product"
              onClick={() => openLightbox(img3)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTwo;
