import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Anasayfa2 = () => {
  const { t, i18n } = useTranslation();
  const [hoverIndex, setHoverIndex] = useState(0)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Bir süre sonra loading ekranını kaldır
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1.5 saniye bekletme süresi

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='overflow-hidden h-screen'>
      <AnimatePresence>
        {loading ? (
          <motion.div
            key="loading"
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            initial={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="top-0 left-0 h-screen w-full z-50 flex justify-center items-center"
          >
            <img src="/images/logo/logo.svg" className="w-[50vw] md:w-[20vw]" />
          </motion.div>
        ) : (
          <motion.div
            key="content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="h-screen w-full grid grid-cols-1 md:grid-cols-2 relative overflow-hidden"
          >


            <motion.div onHoverStart={()=>setHoverIndex(1)} onHoverEnd={()=>setHoverIndex(0)}>
            <Link to={'/ev-tekstil'} className=" h-[50vh] md:h-screen !block relative group">
              <motion.img
                animate={{ translateY: 0 ,  scale: hoverIndex === 1 ? 1.1 : 1}}
                initial={{ translateY: 1000 ,scale:1 }}
                transition={{ duration: hoverIndex?0.5:1 ,delay:hoverIndex?0:1,ease:'easeInOut'}}

                exit={{ opacity: 0 }}
                src="/images/anasayfa2/ev-tekstil.png"
                className="h-full w-full object-cover"
              />
                 <div className='absolute flex justify-center items-center top-0 left-0 h-full w-full'>
              <motion.h5  animate={{height:'auto'}} initial={{height:0}}transition={{ duration: 0.5 ,delay:2}} className=" text-5xl  md:text-8xl overflow-hidden text-white font-thin  text-center">{t('ev-tekstil')}</motion.h5>
              </div>
            </Link>
            </motion.div>


            <motion.div onHoverStart={()=>setHoverIndex(2)} onHoverEnd={()=>setHoverIndex(0)}>


            <Link to={'/ev-giyim'} className="h-[50vh] md:h-screen !block  relative">
              <motion.img
                              animate={{ translateY: 0 ,  scale: hoverIndex === 2 ? 1.1 : 1}}
                              initial={{ translateY: -1000,scale:1 }}
                              transition={{ duration: hoverIndex?0.5:1 ,delay:hoverIndex?0:1,ease:'easeInOut'}}
                              exit={{ opacity: 0 }}
                src="/images/anasayfa2/ev-giyim.png"
                className="h-full w-full object-cover"
              />
                 <div className='absolute flex justify-center items-center top-0 left-0 h-full w-full'>
                 <motion.h5 animate={{height:'auto'}} initial={{height:0}} transition={{ duration: 0.5 ,delay:2.5}} className=" text-5xl md:text-8xl overflow-hidden text-white font-thin  text-center">{t('ev-giyim')}</motion.h5>
              </div>
            </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Anasayfa2;
