import React from 'react'
import { useParams } from 'react-router-dom'
import CustomGallery from '../../../components/Gallery/CustomGallery'
import CustomSingleGallery from '../../../components/Gallery/CustomSingleGallery'


const bizdenHaberlerData = [
    {
        id: 0,
        title: 'PEMBE ŞEHİR PROJESİ İLE KADINLARIMIZIN YANINDAYIZ.',
        mainImage: '/images/basin/bizden-haberler/zeybekci.jpg',
        text: 'Cumhurbaşkanlığı Ekonomi Politikaları Kurulu Başkan Vekili Sn Nihat Zeybekci ile Turkuaz Tekstil San.Tic.A.Ş Yönetim Kurulu Başkanı Ayşen Zeybekci’den Pembe Şehir Projesine tam destek. Turkuaz Tekstil San.Tic.A.Ş.’de 150 kadın eğitimden geçerken Zeybekci “Kadınlarımızın her zaman yanındayız” dedi. Denizli İl Sağlık Müdürlüğümüz ve Denizli İş Kadınları Birliği (DİKAB) işbirliğiyle başlatılan Pembe Şehir Projesi kapsamında sürdürülen kanser eğitimi ve farkındalık çalışmaları devam ediyor. Denizli Turkuaz Tekstil’de çalışan toplam 150 kadın kanser hakkında bilgilendirildi.',
        outLinks: [
            'http://pamukkalehaber.com/gunun-icinden/zeybekci-kadinlarimizin-her-zaman-yanindayiz/45423',
            'http://habercidenizli.com/zeybekciden-pembe-sehir-projesine-destek-15447.html',
            'http://www.denizlidesiyaset.com/pembe-sehir-projesine-tam-destek-geldi/5108/'
        ],
        imageData: [
            {
                id: 0,
                image: '/images/basin/bizden-haberler/zeybekci-1.jpg',
                alt: '',
            },
            {
                id: 1,
                image: '/images/basin/bizden-haberler/zeybekci-2.jpg',
                alt: '',
            },
            {
                id: 2,
                image: '/images/basin/bizden-haberler/zeybekci-3.jpg',
                alt: '',
            },
            {
                id: 3,
                image: '/images/basin/bizden-haberler/zeybekci-4.jpg',
                alt: '',
            },
            {
                id: 4,
                image: '/images/basin/bizden-haberler/zeybekci-5.jpg',
                alt: '',
            },
        ]
    },
    {
        id: 1,
        title: 'ÜNLÜ MODACI HAKAN AKKAYA TURKUAZ TEKSTİL’DE',
        mainImage: '/images/basin/bizden-haberler/hakanakkaya-1.jpeg',
        text: 'Dünyaca tanınmış, birçok başarıya imza atan ve New York moda haftasının takvimine giren İlk Türk tasarımcımız Modanın Dahi Çocuğu Hakan Akkaya, modern tasarımlar ile %100 Türk kumaşlarını harmanlayarak hazırladığı 2018 koleksiyonunu NYFW podyumunda sergilemişti. Ev tekstili ve Plaj tasarımlarına yeni bir yön vermek isteyen Akkaya; Türk kumaş kalitelerinden oluşan bir koleksiyon hazırlamak adına firmamızı ziyaret etti. Turkuaz tekstil kalitesi ve Hakan Akkaya imzası taşıyan tasarımlar ev tekstili dünyasına damgasını vuracak.',
        outLinks: [

        ],
        imageData: [
            {
                id: 0,
                image: '/images/basin/bizden-haberler/hakanakkaya-2.jpeg',
                alt: '',
            },

        ]
    },
    {
        id: 2,
        title: 'TURKUAZ TEKSTİL EVTEKS 2020 FUARINDA',
        mainImage: '/images/basin/bizden-haberler/evteks2020.jpg',
        text: '',
        outLinks: [

        ],
        imageData: [

        ]
    },

    {
        id: 3,
        title: 'TURKUAZ TEKSTIL 15000M2 ALANA HATIRA ORMANI OLUŞTURDU',
        mainImage: '/images/basin/bizden-haberler/hatiraorman.jpg',
        text: '2015 Mart ayında, Orman Haftası dolayısıyla, Denizli de Turkuaz Tekstil adına bir Hatıra Ormanı oluşturuldu. Ormana ilk fidanlar Ayşen Zeybekci, Atilla Zeybekci ve Turkuaz Tekstil çalışanları tarafından dikildi. 1500 m² alan üzerinde kurulan bu ormanda, ladin, sedir, karaçam ve kızılçam türlerinde yüzlerce fidan toprakla buluştu.',
        outLinks: [

        ],
        imageData: [
            {
                id: 0,
                image: '/images/basin/bizden-haberler/hatiraorman3.jpg',
                alt: '',
            },
            {
                id: 1,
                image: '/images/basin/bizden-haberler/hatiraorman2.jpg',
                alt: '',
            },
            {
                id: 1,
                image: '/images/basin/bizden-haberler/hatiraorman1.jpg',
                alt: '',
            },
        ]
    },




    {
        id: 4,
        title: '7-10 OCAK 2020 HEIMTEXTIL FRANKFURT FUARINA KATILDIK.',
        mainImage: '/images/basin/bizden-haberler/frankurtfuari.jpg',
        text: 'Turkuaz Tekstil olarak Frankfurt ta düzenlenen dünyanın en önemli ev tekstil fuarlarının başında gelen Heimtextil Fuarına katıldık.',
        outLinks: [

        ],
        imageData: [

        ]
    },
]

const BizdenHaberlerInner = () => {
    const { id } = useParams()
    const numericId = Number(id)


    const filteredData = bizdenHaberlerData.find(item => item.id === numericId)




    if (!filteredData) {
        return <p>Data not found</p>
    }

    const { title, mainImage, text, outLinks, imageData } = filteredData
    return (
        <div className='w-full  '>
            {
                filteredData &&
                <div className='mt-14 lg:mt-0'>
                    <h5 className='text-35 mb-10'>{title}</h5>
                    <div className='grid grid-cols-3 gap-5'>
                        <div className='col-span-3 lg:col-span-1 h-96'>
                            <CustomSingleGallery mainImage={mainImage} />
                        </div>
                        <div className='col-span-3 lg:col-span-2'>
                            <p>{text}</p>
                        </div>
                    </div>

                    {outLinks && <div className='flex flex-col my-5'>
                        {outLinks.map((link, index) => (
                            <a key={index} target='_blank' href={link}>
                                {link}
                            </a>
                        ))}
                    </div>}
                    <h5 className='text-22 mb-5'>GALERİ</h5>
                    <CustomGallery imageData={imageData} />
                </div>

            }
        </div>
    )
}

export default BizdenHaberlerInner