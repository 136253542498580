import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const Kataloglar = () => {
  const { t, i18n } = useTranslation();
    const cataloglarData = [
        {
            image:'/images/basin/kataloglar/katalogic.png',
            pdf:'/pdf/kataloglar/e-katalog-en.pdf'
        },
    ]
  return (
    <>
      <div className='col-span-2 '>

        
      </div>
        <div className='col-span-7  '>
            <div>    <h5 className='text-35 mb-5'>{t('kataloglar')}</h5></div>
            <div className='h-96 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5 mb-24'>
                    
            {
                cataloglarData.map((item,index)=>{
                    return(
                        <a target='_blank' href={item.pdf}>
                        <img src={item.image} className='aspect-square w-full object-cover'/>
                        </a>
                    )
                })
            }
            </div>
           
      </div>
    </>
  )
}

export default Kataloglar