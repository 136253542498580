export const HomeSliderData = [
    {
        image: '/images/ev-tekstil/hero-slider/0.jpg',
        title: { tr: 'BORNOZ', en: 'BATHROBE' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/bornoz-grubu'
    },
    {
        image: '/images/ev-tekstil/hero-slider/1.jpg',
        title: { tr: 'OTEL', en: 'HOTEL' },
        category: { tr: 'BANYO GRUBU', en: 'BATHROOM GROUP' },
        link: '/ev-tekstil/urunler/banyo-grubu/otel'
    },
    {
        image: '/images/ev-tekstil/hero-slider/2.jpg',
        title: { tr: 'PAS PAS', en: 'BATH MAT' },
        category: { tr: 'BANYO GRUBU', en: 'BATHROOM GROUP' },
        link: '/ev-tekstil/urunler/banyo-grubu/pas-pas'
    },
    {
        image: '/images/ev-tekstil/hero-slider/3.jpg',
        title: { tr: 'PROMOSYON', en: 'PROMOTION' },
        category: { tr: 'BANYO GRUBU', en: 'BATHROOM GROUP' },
        link: '/ev-tekstil/urunler/banyo-grubu/promosyon'
    },
    {
        image: '/images/ev-tekstil/hero-slider/4.jpg',
        title: { tr: 'ÇOCUK', en: 'CHILD' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/cocuk-grubu'
    },
    {
        image: '/images/ev-tekstil/hero-slider/5.jpg',
        title: { tr: 'HAVLU', en: 'TOWEL' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/havlu-grubu'
    },
    {
        image: '/images/ev-tekstil/hero-slider/6.jpg',
        title: { tr: 'MUTFAK', en: 'KITCHEN' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/mutfak-grubu'
    },
    {
        image: '/images/ev-tekstil/hero-slider/7.jpg',
        title: { tr: 'PEŞTEMAL', en: 'PESHTEMAL' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/pestemal'
    },
    {
        image: '/images/ev-tekstil/hero-slider/8.jpg',
        title: { tr: 'PLAJ', en: 'BEACH' },
        category: { tr: 'GRUBU', en: 'GROUP' },
        link: '/ev-tekstil/urunler/plaj'
    },
];
