import React from 'react'
import Urunler from '../pages/Urunler'
import { DATA } from '../data/products/ev-tekstil-urunler-data'

const EvTekstilUrunlerContainer = () => {
  return (
    <Urunler DATA={DATA}/>
  )
}

export default EvTekstilUrunlerContainer