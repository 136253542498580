import React from 'react';

const OneOne = ({ img1, img2, openLightbox }) => {
  return (
    <div className='w-full grid grid-cols-8 mb-10'>
      <div className='col-start-2 col-end-8 grid grid-cols-2 gap-5'>
        <div className='w-full   max-h-[50vh]'>
          <img
            src={img1}
            className='w-full h-full object-cover cursor-pointer'
            alt="Product"
            onClick={() => openLightbox(img1)}
          />
        </div>
        <div className='w-full h-full  max-h-[50vh]'>
          <img
            src={img2}
            className='w-full h-full object-cover cursor-pointer'
            alt="Product"
            onClick={() => openLightbox(img2)}
          />
        </div>
      </div>
    </div>
  );
};

export default OneOne;
