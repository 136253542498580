import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeMenu = () => {
    const { t, i18n } = useTranslation();
    const mainData = [
        { title: { tr: 'KURUMSAL', en: 'CORPORATE' }, link: '/kurumsal', image: '/images/anasayfa-menu/kurumsal.jpg' },
        { title: { tr: 'MARKALAR', en: 'BRANDS' }, link: '/markalar', image: '/images/anasayfa-menu/marka.jpg' },
        { title: { tr: 'SERTİFİKALAR', en: 'CERTIFICATES' }, link: '/sertifikalar', image: '/images/anasayfa-menu/sertifika.jpg' },
        { title: { tr: 'İLETİŞİM', en: 'CONTACT' }, link: '/iletisim', image: '/images/anasayfa-menu/iletisim.jpg' },
    ];

    const secondaryData = [
        { title: { tr: 'BİZDEN HABERLER', en: 'NEWS FROM US' }, link: '/basin-odasi/bizden-haberler', image: '/images/anasayfa-menu/bizden-haberler/1.svg' },
        { title: { tr: 'FUARLAR', en: 'FAIRS' }, link: '/basin-odasi/fuarlar', image: '/images/anasayfa-menu/bizden-haberler/2.svg' },
        { title: { tr: 'KATALOGLAR', en: 'CATALOGS' }, link: '/basin-odasi/kataloglar', image: '/images/anasayfa-menu/bizden-haberler/3.svg' },
        { title: { tr: 'VİDEOLAR', en: 'VIDEOS' }, link: '/basin-odasi/videolar', image: '/images/anasayfa-menu/bizden-haberler/4.svg' },
    ];

    return (
        <section className="grid grid-cols-9">
            <div className="col-start-1 col-end-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                {mainData.map((item, index) => (
                    <Link to={item.link} key={index}>
                        <motion.div
                            className=""
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <div className='h-full w-full relative overflow-hidden aspect-square group'>
                                <div className='absolute h-full w-full z-50 bg-black opacity-10 group-hover:opacity-0 transition-all duration-500'></div>
                                <div className='absolute h-full w-full text-black flex items-center justify-center z-50 opacity-100 group-hover:text-white group-hover:opacity-70 transition-all duration-500 text-2xl md:text-5xl font-thin'>
                                    {item.title[i18n.language]}
                                </div>
                                <img src={item.image} className='h-full w-full object-cover group-hover:scale-105 transition-all duration-500' />
                                <img src='/images/icons/push-down.svg' className='absolute z-[50] bottom-5 right-5 rotate-180 h-5 w-5 opacity-0 group-hover:opacity-100 transition-all duration-500 -translate-x-5 translate-y-5 group-hover:translate-x-0 group-hover:translate-y-0' />
                            </div>
                        </motion.div>
                    </Link>
                ))}
            </div>

            <div className="col-start-1 col-end-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-px">
                {secondaryData.map((item, index) => (
                    <Link to={item.link} key={index}>
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 + 1 }}
                        >
                            <div className='h-full w-full bg-[#F8F6ED] relative overflow-hidden aspect-square group flex items-center'>
                                <div className='absolute h-full w-full top-0 left-0 flex justify-center items-center'>
                                    <h5 className='opacity-0 transition-all duration-500 group-hover:opacity-100 font-medium text-base text-center top-28 w-full z-40 tracking-widest text-[#d1c7ae]'>
                                        {item.title[i18n.language]}
                                    </h5>
                                </div>
                                <img src={item.image} className='opacity-100 group-hover:opacity-0 object-cover group-hover:scale-105 transition-all duration-500' />
                            </div>
                        </motion.div>
                    </Link>
                ))}
            </div>
        </section>
    );
};

export default HomeMenu;
