import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Grid } from 'swiper/modules';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/grid';

const variants = {
    visible: index => ({
        opacity: 1,
        scale: 1,
        transition: {
            delay: index * 0.05,
        },
    }),
    hidden: { opacity: 0, scale: 0.8 },
}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 } }

const HomeSlider = ({ HomeSliderData }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language; // Get the current language

    const swiperRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const prevButton = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const nextButton = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <motion.div className='grid grid-cols-9 relative my-5'>
            <div className='absolute w-full -bottom-5 h-28 lg:h-96 bg-[#695D54]'></div>
            <div onClick={prevButton} className='h-full w-full flex items-center justify-end pr-[3%] cursor-pointer'>
                <img src='/images/icons/left.svg' alt="Previous Slide" />
            </div>
            <div className='col-start-2 col-end-9'>
                <Swiper
                    ref={swiperRef}
                    slidesPerView={2}
                    spaceBetween={10}
                    autoplay={{
                        delay: 5000, 
                        disableOnInteraction: false, 
                    }}
                    grid={{
                        rows: 2,
                        fill: 'row',
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                            grid: {
                                rows: 2,
                                fill: 'row',
                            }
                        },
                    }}
                    modules={[Grid, Autoplay]}
                    className='w-full absolute z-40'
                    onSlideChange={(swiper) => { setCurrentSlide(swiper.activeIndex) }}
                >
                    {HomeSliderData && HomeSliderData.map((item, index) => (
                        <SwiperSlide key={index} className='h-96 relative rounded-xl lg:rounded-3xl overflow-hidden'>
                            <Link to={item.link} className='cursor-pointer transition-all duration-500 hover:scale-105 relative block'>
                                <div className='absolute top-0 left-0 h-full w-full flex flex-col justify-start items-start z-30 p-[7%]'>
                                    <h5 className='text-14 sm:text-18 lg:text-32 font-light'>
                                        {item.title[currentLanguage]}
                                    </h5>
                                    <h5 className='text-14 sm:text-18 lg:text-24 font-light'>
                                        {item.category[currentLanguage]}
                                    </h5>
                                </div>
                                <motion.img
                                    custom={index}
                                    whileInView="visible"
                                    initial="hidden"
                                    variants={variants}
                                    src={item.image}
                                    className='h-full w-full object-cover'
                                    alt={item.title[currentLanguage]}
                                />
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div onClick={nextButton} className='h-full w-full flex items-center justify-start pl-[3%] cursor-pointer'>
                <img src='/images/icons/right.svg' alt="Next Slide" />
            </div>
        </motion.div>
    );
};

export default HomeSlider;
