import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLenis } from '@studio-freight/react-lenis';
import { useTranslation } from 'react-i18next';

const DATA = [
    {
        img: '/images/footer/references/1.jpg',
        name: ''
    },
    {
        img: '/images/footer/references/2.jpg',
        name: ''
    },
    {
        img: '/images/footer/references/3.jpg',
        name: ''
    },
    {
        img: '/images/footer/references/4.jpg',
        name: ''
    },
    {
        img: '/images/footer/references/5.jpg',
        name: ''
    },
];

const footerData = [
    {
        title: { tr: 'ANASAYFA', en: 'HOME' },
        link: ''
    },
    {
        title: { tr: 'ÜRÜNLER', en: 'PRODUCTS' },
        links: [
            {
                title: { tr: 'Ev Tekstili', en: 'Home Textiles' },
                link: '/ev-tekstil'
            },
            {
                title: { tr: 'Ev Giyim', en: 'Home Wear' },
                link: '/ev-giyim'
            },
        ]
    },
    {
        title: { tr: 'MARKALAR', en: 'BRANDS' },
        links: [
            {
                title: { tr: 'Turkuaz', en: 'Turkuaz' },
                link: ''
            },
            {
                title: { tr: 'Chira', en: 'Chira' },
                link: '/markalar'
            },
            {
                title: { tr: 'Chysis', en: 'Chysis' },
                link: '/markalar'
            },
            {
                title: { tr: 'Bionatura', en: 'Bionatura' },
                link: '/markalar'
            },
            {
                title: { tr: 'Hakan Akkaya By', en: 'Hakan Akkaya By' },
                link: '/markalar'
            },
            {
                title: { tr: 'Turkuaz', en: 'Turkuaz' },
                link: '/markalar'
            },
        ]
    },
    {
        title: { tr: 'BASIN ODASI', en: 'PRESS ROOM' },
        links: [
            {
                title: { tr: 'Bizden Haberler', en: 'News from Us' },
                link: '/basin-odasi/bizden-haberler'
            },
            {
                title: { tr: 'Fuarlar', en: 'Fairs' },
                link: '/basin-odasi/fuarlar'
            },
            {
                title: { tr: 'Kataloglar', en: 'Catalogs' },
                link: '/basin-odasi/kataloglar'
            },
            {
                title: { tr: 'Videolar', en: 'Videos' },
                link: '/basin-odasi/videolar'
            },
        ]
    },
    {
        title: { tr: 'İLETİŞİM', en: 'CONTACT' },
        link: '/iletisim',
        links: [
            {
                title: { tr: 'Facebook', en: 'Facebook' },
                link: ''
            },
            {
                title: { tr: 'Instagram', en: 'Instagram' },
                link: ''
            },
            {
                title: { tr: 'Linkedin', en: 'LinkedIn' },
                link: ''
            },
            {
                title: { tr: 'Youtube', en: 'YouTube' },
                link: ''
            },
        ]
    },
];

const Footer = () => {
    const { t, i18n } = useTranslation();
    const lenis = useLenis();

    const handleScrollToTop = () => {
        if (lenis) {
            lenis.scrollTo(0);
        }
    };

    const location = useLocation();
    if (location.pathname === '/') {
        return null;
    }

    return (
        <section className="grid grid-cols-9 bg-[#F4F3F3] py-20 overflow-x-hidden">
            <div className="col-start-2 col-end-9 flex flex-col w-full">
                <div className='w-full mb-10 flex flex-col lg:flex-row lg:justify-between'>
                    <div className='h-full mb-10 lg:mb-0'>
                        <img src='/images/logo/logo.svg' className='h-20' alt="Logo" />
                    </div>
                    <div className='h-full flex justify-start lg:justify-end w-full'>
                        {
                            DATA.map((item, index) => (
                                <img key={index} src={item.img} className='w-1/5 lg:w-auto h-auto lg:h-20 mix-blend-multiply' alt="Reference" />
                            ))
                        }
                    </div>
                </div>

                <div className='w-full grid grid-cols-5'>
                    <div className='w-full h-full col-span-5 md:col-span-1 font-light text-16 text-[#AFAEAE]'>
                        <h5 className='font-medium mb-5'>{t('iletisim-lowcase')}</h5>
                        <a href='https://maps.app.goo.gl/UULF6WH9EV4wj9kx6' target='_blank' rel='noopener noreferrer'>
                            <h5>Akçeşme, 2053. Sk. <br />No:3, 20020 <br /> Merkezefendi/Denizli</h5>
                        </a>
                        <a href='https://maps.app.goo.gl/jCoZpL8S4NgH1JM16' target='_blank' rel='noopener noreferrer' className='transition-all duration-500 hover:scale-105 text-18 py-2 px-6 bg-[#636363] font-medium mb-10 text-white mt-5 inline-flex justify-center space-x-2'>
                            <h5>{t('iletisim-yol-tarifi')}</h5>
                            <img src='/images/icons/kesfet-right.svg' className='w-5' alt="Arrow" />
                        </a>
                        <a href='tel:+902583717453' target='_blank' rel='noopener noreferrer'><h5 className='mb-1'>+90 258 371 74 53</h5></a>
                        <a href='mailto:turkuaz@turkuaztex.com' target='_blank' rel='noopener noreferrer'><h5>turkuaz@turkuaztex.com</h5></a>
                    </div>
                    <div className='w-full h-full col-span-5 md:col-span-3 grid md:grid-cols-5 text-16'>
                        {
                            footerData.map((item, index) => (
                                <div key={index} className='text-[#AFAEAE] md:pl-11'>
                                    <Link to={item.link ?? item.link}><h4 className='font-medium mb-5 mt-10 lg:mt-0'>{item?.title?.[i18n.language]}</h4></Link>
                                    {
                                        item.links?.map((linkItem, linkIndex) => (
                                            <Link to={linkItem.link} key={linkIndex}><h4 className='font-light'>{linkItem?.title?.[i18n.language]}</h4></Link>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className='w-full mt-5 lg:mt-0 h-full col-span-5 flex-row-reverse lg:col-span-1 flex lg:flex-col justify-between items-end'>
                        <img onClick={handleScrollToTop} src='/images/logo/up.svg' className='h-10 cursor-pointer scale-105 transition-all duration-300' alt="Scroll to top" />
                        <a href='https://www.zeybekciholding.com/' target='_blank' rel='noopener noreferrer' className='block'><img src='/images/logo/zeybekci-logo-1.svg' className='h-10' alt="Zeybekci Holding" /></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
