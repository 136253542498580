import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import Footer from '../components/Footer';
import Header from '../components/Header/Header';

const PageContainer = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // Scroll to top unless the pathname starts with 'basin-odasi'
    if (!location.pathname.startsWith('/basin-odasi')) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const pageVariants = {
    initial: { translateY: -10, opacity: 0 },
    in: { translateY: 0, opacity: 1 },
    out: { translateY: 10, opacity: 0 },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeIn',
    duration: 1,
  };

  return (
    <div className='relative'>
      <Header />
      <div className='relative z-40 bg-white'>
        <motion.div
          key={location.pathname}
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition}
        >
          <Outlet />
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default PageContainer;
